<template>
  <div>
    <!-- Body Card -->
    <el-card shadow="hover" class="menuCard">
      <!-- Top Level Menu -->
      <el-row class="item">
        <el-col class="titleHeader" :span="4">
          <div
            :style="`font-size: var(--el-font-size-extra-large); text-align: left`"
          >
            Media Slider Manager
          </div>
        </el-col>
        <el-col :span="10" class="text-right">
          <div class="newButton">
            <el-button
              type="primary"
              @click="createMediaSliderManagerVisible = true"
            >
              Add
            </el-button>
            <el-button type="info" @click="showFilesDialog = true"
              >Show upload images / videos</el-button
            >
            <el-button
              @click="handleShowHiddenData('showHidden')"
              v-show="!hiddenButton"
            >
              Show Hidden Data
            </el-button>
            <el-button
              @click="handleShowHiddenData('showUnhidden')"
              v-show="hiddenButton"
            >
              Unshow Hidden Data
            </el-button>
          </div>
        </el-col>
      </el-row>
      <!-- Top Level Menu -->

      <!-- Content -->
      <el-skeleton :loading="loading" animated :count="1" :throttle="500">
        <template #default>
          <div>
            <!-- Insert Grid here -->
            <div v-if="errorLoading === false">
              <!-- Display Control Area -->
              <el-row>
                <!-- Display Controls -->
              </el-row>
              <!-- Display Control Area -->

              <!-- Grid or Table  Add Logic on DIV-->
              <div class="bodyContent">
                <!-- Grid or Table  Empty Data Logic Handler on DIV -->
                <div class="tableContent">
                  <el-scrollbar always>
                    <el-table
                      :data="listOfMediaSliderManager"
                      style="width: 100%"
                      v-loading="loading"
                    >
                      <el-table-column label="Image / Video">
                        <template #default="{ row }">
                          <div v-if="row.fileType.startsWith('png')">
                            <img
                              :src="'https://mediabinapi.siweb01.vm.aws.cloud.skanlog.com/' + row.filePath"
                              width="250"
                              height="250"
                            />
                          </div>
                          <div v-else-if="row.fileType.startsWith('mp4')">
                            <video
                              :src="'https://mediabinapi.siweb01.vm.aws.cloud.skanlog.com/' + row.filePath"
                              controls
                              width="250"
                              height="250"
                            />
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column align="right">
                        <template #default="scope">
                          <el-button
                            size="small"
                            @click="handleAction('edit', scope.row)"
                            >Add Quote</el-button
                          >
                          <el-button
                            v-show="hiddenButton"
                            size="small"
                            @click="handleAction('unhide', scope.row)"
                            >Unhide</el-button
                          >
                          <el-button
                            v-show="!hiddenButton"
                            size="small"
                            @click="handleAction('hide', scope.row)"
                            >Hide</el-button
                          >
                          <el-button
                            size="small"
                            type="danger"
                            @click="handleAction('delete', scope.row)"
                            >Delete</el-button
                          >
                        </template>
                      </el-table-column>
                    </el-table>
                    <!-- Main Content  -->
                  </el-scrollbar>
                </div>
              </div>
              <!-- Grid or Table -->
              <!-- Emptry State Summary -->
              <!-- Emptry State Summary -->
            </div>

            <!-- Error Loading Empty state -->
            <el-empty description="Error loading " v-else>
              <el-button>Reload Table</el-button>
            </el-empty>
            <!-- Error Loading Empty state -->
          </div>
        </template>
      </el-skeleton>
      <!-- Content -->
    </el-card>

    <!-- Dialog -->
    <!-- Create MediaSliderManager Dialog -->
    <el-dialog
      v-model="createMediaSliderManagerVisible"
      title="Upload photos / videos"
      @keydown.esc="clearForm()"
    >
      <el-form :model="form" :label-position="labelPosition">
        <el-form-item
          label="Upload Images / Videos:"
          :label-width="formLabelWidth"
        >
          <el-upload
            v-model:file-list="listOfImages"
            class="upload-demo"
            action="https://mediabinapi.siweb01.vm.aws.cloud.skanlog.com/api/MediaSliderManager/UploadPhoto"
            method="get"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :data="{ mediaSliderManagerId: form.mediaSliderManagerId }"
            list-type="text"
            multiple
            v-loading="uploadLoading"
          >
            <el-button type="primary">Click to upload</el-button>
            <template #tip>
              <div class="el-upload__tip">
                jpg/png files with a size less than 500kb
              </div>
            </template>
          </el-upload>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="clearForm()">Cancel</el-button>
          <el-button
            type="primary"
            @click="
              form.mediaSliderManagerId
                ? editMediaSliderManager()
                : addMediaSliderManager(form)
            "
            >Confirm</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- Create MediaSliderManager Dialog -->

    <!-- Action Dialog -->
    <el-dialog v-model="actionDialogVisible" title="Warning" width="30%" center>
      <span text-align="center"
        >Are you sure you want to {{ actionType }} this data?</span
      >
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="actionDialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="performAction()">Confirm</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- Action Dialog -->

    <!-- Show Image Dialog -->
    <el-dialog
      v-model="showFilesDialog"
      title="Uploaded Images and Videoss"
      width="30%"
      align-center
    >
      <el-carousel :interval="4000" arrow="always" height="200px">
        <el-carousel-item v-for="item in listOfMediaSliderManager" :key="item">
          <div v-if="item.fileType.startsWith('mp4')">
            <video width="100%" height="auto" autoplay muted>
              <source
                :src="'https://mediabinapi.siweb01.vm.aws.cloud.skanlog.com/' + item.filePath"
                type="video/mp4"
              />
            </video>
          </div>
          <div v-else-if="item.fileType.startsWith('png')">
            <el-image
              :src="'https://mediabinapi.siweb01.vm.aws.cloud.skanlog.com' + item.filePath"
              alt="slide"
              width="100%"
            />
          </div>
        </el-carousel-item>
      </el-carousel>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="showFilesDialog = false">Close</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- Show Image Dialog -->

    <!-- Add Quote Dialog -->
    <el-dialog
      v-model="showAddQuoteDialog"
      title="Add Quote"
      width="30%"
      align-center
    >
      <el-form>
        <el-form-item label="Brief Description:" :label-width="formLabelWidth">
          <el-input type="textarea" :rows="5" :cols="30" v-model="form.quote"
        /></el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="showAddQuoteDialog = false">Close</el-button>
          <el-button @click="addQuote()">Confirm</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- Add Quote Dialog -->
    <!-- Dialog -->
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from "vue";
import api from "@/services/apiService";
import { ElMessage } from "element-plus";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "MediaSliderManager",
  data() {
    return {
      loading: false,
      errorLoading: false,
      listOfMediaSliderManager: null,
      listOfImages: [],
      createMediaSliderManagerVisible: false,
      deleteDialogVisible: false,
      labelPosition: ref("top"),
      formLabelWidth: "140px",
      deleteType: "",
      showFilesDialog: false,
      uploadLoading: false,
      actionType: "",
      hiddenButton: false,
      showInput: false,
      actionDialogVisible: false,
      showAddQuoteDialog: false,

      url: "",
      urlList: [],

      form: reactive({
        //MediaSliderManager Properties
        mediaSliderManagerId: null,
        fileName: "",
        filePath: "",
        fileType: "",
        quote: "",
      }),
    };
  },
  computed: {
    ...mapGetters({
      user: "returnUserRole",
      oidcUser: "oidcStore/oidcUser",
    }),
  },

  methods: {
    handleShowHiddenData(action) {
      if (action === "showHidden") {
        this.getHiddenMediaSliderManager();
        this.hiddenButton = true;
      } else if (action === "showUnhidden") {
        this.getMediaSliderManager();
        this.hiddenButton = false;
      }
    },
    handleShowDetails(row) {
      this.showDetailsVisible = true;
      this.form = row;
    },
    handleAction(action, row) {
      this.actionType = action;
      this.form.mediaSliderManagerId = row.mediaSliderManagerId;
      if (action === "delete" || action === "hide" || action === "unhide") {
        this.actionDialogVisible = true;
      } else {
        this.showAddQuoteDialog = true;
      }
    },
    performAction() {
      if (this.actionType === "delete") {
        this.deleteMediaSliderManager();
      } else if (this.actionType === "hide") {
        this.hideMediaSliderManager();
      } else if (this.actionType === "unhide") {
        this.unhideMediaSliderManager();
      }
    },

    handlePreview(file) {},
    handleRemove(file) {},
    clearForm() {
      this.createMediaSliderManagerVisible = false;
      this.form = {
        mediaSliderManagerId: null,
        fileName: "",
        filePath: "",
        fileType: "",
      };
      this.listOfImages = [];
      this.showInput = false;
      this.showDetailsVisible = false;
    },

    async getMediaSliderManager() {
      this.loading = true;
      await api
        .get("/MediaSliderManager")
        .then((response) => {
          this.listOfMediaSliderManager = response.data;
        })
        .catch((error) => {
          ElMessage.error("Failed to retrieve data from the server.");
          console.error(error);
        });
      this.loading = false;
    },

    async getHiddenMediaSliderManager() {
      this.loading = true;
      await api
        .get("/MediaSliderManager/Hidden")
        .then((response) => {
          this.listOfMediaSliderManager = response.data;
        })
        .catch((error) => {
          ElMessage.error("Failed to retrieve data from the server.");
          console.error(error);
        });
      this.loading = false;
    },

    async addMediaSliderManager() {
      const files = this.listOfImages.map((image) => image.raw); // get an array of selected files
      const totalFiles = files.length;
      let uploadedFiles = 0;
      this.uploadLoading = true;
      for (const file of files) {
        const formData = new FormData();
        if (file.type.startsWith("video/")) {
          formData.append("fileType", "mp4");
          formData.append("fileSize", file.size);
        } else if (file.type.startsWith("image/")) {
          formData.append("fileType", "png");
        }
        formData.append("file", file);

        await api
          .post("/MediaSliderManager/", formData)
          .then(() => {
            uploadedFiles++;
            if (uploadedFiles == totalFiles) {
              ElMessage.success("File/s successfully uploaded.");
              this.getMediaSliderManager();
              this.createMediaSliderManagerVisible = false;
              this.clearForm();
              this.uploadLoading = false;
            }
          })
          .catch(() => {
            ElMessage.error("Error uploading file to the server.");
            this.clearForm();
          });
      }
    },
    async deleteMediaSliderManager() {
      await api
        .delete(`MediaSliderManager/${this.form.mediaSliderManagerId}`)
        .then(() => {
          this.actionDialogVisible = false;
          ElMessage.success("Data successfully deleted!");
          this.clearForm();
          if (this.hiddenButton) {
            this.getHiddenMediaSliderManager();
          } else {
            this.getMediaSliderManager();
          }
        })
        .catch(() => {
          ElMessage.error("Error fetching data from the server.");
        });
    },
    async hideMediaSliderManager() {
      await api
        .patch(`MediaSliderManager/Hide/${this.form.mediaSliderManagerId}`)
        .then(() => {
          this.actionDialogVisible = false;
          ElMessage.success("Data successfully hidden!");
          this.getMediaSliderManager();
          this.clearForm();
        })
        .catch(() => {
          ElMessage.error("Error fetching data from the server.");
        });
    },
    async unhideMediaSliderManager() {
      await api
        .patch(`MediaSliderManager/Unhide/${this.form.mediaSliderManagerId}`)
        .then(() => {
          this.actionDialogVisible = false;
          ElMessage.success("Data successfully unhidden!");
          this.getHiddenMediaSliderManager();
          this.clearForm();
        })
        .catch(() => {
          ElMessage.error("Error fetching data from the server.");
        });
    },

    async addQuote() {
      await api
        .patch(
          `MediaSliderManager/AddQuote/${this.form.mediaSliderManagerId}`,
          {
            ...this.form,
          },
        )
        .then(() => {
          ElMessage.success("Quote Added!");
          this.showAddQuoteDialog = false;
          this.clearForm();
          this.getMediaSliderManager();
        })
        .catch(() => {
          ElMessage.error("error");
        });
    },
  },
  created() {
    this.getMediaSliderManager();
  },
  async mounted() {
    const userID = this.oidcUser.sub;
    //  await this.$store.dispatch("getUserRole", userID);
  },
});
</script>

<style>
.my-autocomplete {
  width: 100%;
}

.pac-container {
  z-index: 9999;
  position: absolute;
  width: 100%;
}

.tox .tox-notification--error {
  display: none !important;
}

.tox-tinymce-aux {
  z-index: 5000 !important;
}

.item {
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.menuCard {
  min-height: 50vh;
  max-height: calc(92vh - 32px);
  overflow: hidden;
}

.tableContent {
  max-height: calc(82vh - 32px);
  overflow: auto;
}

.bodyContent {
  padding-top: 0.5rem;
}
.newButton {
  text-align: end;
}
</style>
