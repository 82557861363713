<template>
  <div>
    <!-- Body Card -->
    <el-card shadow="hover" class="menuCard">
      <!-- Top Level Menu -->
      <el-row class="item">
        <el-col class="titleHeader" :span="3">
          <div
            :style="`font-size: var(--el-font-size-extra-large); text-align: left`"
          >
            Location
          </div>
        </el-col>
      </el-row>
      <!-- Top Level Menu -->

      <!-- Content -->
      <el-skeleton :loading="loading" animated :count="1" :throttle="500">
        <template #default>
          <div>
            <!-- Insert Grid here -->
            <div v-if="errorLoading === false">
              <!-- Display Control Area -->
              <el-row>
                <!-- Display Controls -->
              </el-row>
              <!-- Display Control Area -->

              <!-- Grid or Table  Add Logic on DIV-->
              <div class="bodyContent">
                <!-- Grid or Table  Empty Data Logic Handler on DIV -->
                <div class="tableContent">
                  <el-scrollbar always>
                    <el-table
                      :data="listOfLocation"
                      style="width: 100%"
                      v-loading="loading"
                    >
                      <el-table-column label="Address" prop="address" />
                      <el-table-column align="right">
                        <template #default="scope">
                          <el-button size="small" @click="handleEdit(scope.row)"
                            >Edit</el-button
                          >
                        </template>
                      </el-table-column>
                    </el-table>
                    <!-- Main Content  -->
                  </el-scrollbar>
                </div>
                <div>
                  <GMapMap
                    style="width: 50%; height: 500px"
                    :center="center"
                    ref="myMapRef"
                    :zoom="15"
                    :address="address"
                    :options="{
                      styles: [style],
                      disableDefaultUI: true,
                    }"
                  >
                    <GMapMarker
                      :options="{
                        position: center,
                      }"
                      :clickable="true"
                      @click="openMarker(true)"
                      @closeclick="openMarker(false)"
                    >
                      <GMapInfoWindow
                        :opened="openedMarker"
                        :closeclick="true"
                        @closeclick="openMarker(null)"
                      >
                        <div>
                          {{ address }}<br /><el-button
                            type="primary"
                            round
                            @click="getDirections"
                            ><el-icon><MapLocation></MapLocation></el-icon
                            ><span>Get Direction</span></el-button
                          >
                        </div>
                      </GMapInfoWindow>
                    </GMapMarker>
                  </GMapMap>
                </div>
              </div>
              <!-- Grid or Table -->

              <!-- Emptry State Summary -->
              <!-- Emptry State Summary -->
            </div>

            <!-- Error Loading Empty state -->
            <el-empty description="Error loading " v-else>
              <el-button>Reload Table</el-button>
            </el-empty>
            <!-- Error Loading Empty state -->
          </div>
        </template>
      </el-skeleton>
      <!-- Content -->
    </el-card>

    <!-- Dialog -->
    <!-- Edit Address Dialog -->
    <el-dialog v-model="editFormVisible" title="Address Details">
      <el-form :model="form" :label-position="labelPosition">
        <el-form-item label="Building Name:" :label-width="formLabelWidth">
          <el-input v-model="form.buildingName" />
        </el-form-item>
        <el-form-item label="Street:" :label-width="formLabelWidth">
          <el-input v-model="form.street" />
        </el-form-item>
        <el-form-item label="City:" :label-width="formLabelWidth">
          <el-input v-model="form.city" />
        </el-form-item>
        <el-form-item label="State:" :label-width="formLabelWidth">
          <el-input v-model="form.state" />
        </el-form-item>
        <el-form-item label="Postal Code:" :label-width="formLabelWidth">
          <el-input v-model="form.postalCode" />
        </el-form-item>
        <el-form-item label="Country:" :label-width="formLabelWidth">
          <el-input v-model="form.country" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="clearForm()">Cancel</el-button>
          <el-button type="primary" @click="editLocation()">Confirm</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- Edit Address Dialog -->

    <!-- Dialog -->
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from "vue";
import api from "@/services/apiService";
import { ElMessage } from "element-plus";
import { mapGetters } from "vuex";
import { MapLocation } from "@element-plus/icons";

export default defineComponent({
  name: "Location",
  components: {
    MapLocation,
  },
  data() {
    return {
      loading: false,
      errorLoading: false,
      listOfLocation: null,
      labelPosition: ref("top"),
      editFormVisible: false,
      centerDialogVisible: false,
      center: { lat: 0, lng: 0 },
      address: "",
      openedMarker: true,
      style: {
        featureType: "poi",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
      },

      form: reactive({
        locationId: null,
        buildingName: "",
        street: "",
        city: "",
        state: "",
        postalCode: "",
        country: "",
        latitude: null,
        longitude: null,
      }),
    };
  },

  methods: {
    openMarker(decision) {
      this.openedMarker = decision;
    },
    getDirections() {
      const destination = `${this.center.lat},${this.center.lng}`;
      const encodedDestination = encodeURI(destination);
      const url = `https://www.google.com/maps/dir//${encodedDestination}/`;
      window.open(url, "_blank");
    },

    clearForm() {
      this.editFormVisible = false;
      this.form = {
        buildingName: "",
        street: "",
        city: "",
        state: "",
        postalCode: "",
        country: "",
      };
    },
    async getLocation() {
      this.loading = true;
      await api
        .get("/Location")
        .then((response) => {
          this.listOfLocation = response.data;
          const { latitude, longitude } = this.listOfLocation[0];
          this.center = { lat: latitude, lng: longitude };
        })
        .catch((error) => {
          ElMessage.error("Failed to retrieve data from the server.");
          console.error(error);
        });
      this.loading = false;
      this.address = this.listOfLocation[0].buildingName;
    },

    handleEdit(row) {
      this.editFormVisible = true;
      this.form = {
        locationId: row.locationId,
        buildingName: row.buildingName,
        street: row.street,
        city: row.city,
        state: row.state,
        postalCode: row.postalCode,
        country: row.country,
        latitude: row.latitude,
        longitude: row.longitude,
      };
    },

    async editLocation() {
      await api
        .put(`Location/${this.form.locationId}`, {
          locationId: this.form.locationId,
          buildingName: this.form.buildingName,
          street: this.form.street,
          city: this.form.city,
          state: this.form.state,
          postalCode: this.form.postalCode,
          country: this.form.country,
          latitude: this.form.latitude,
          longitude: this.form.longitude,
        })
        .then(() => {
          this.getLocation();
          ElMessage.success("Location successfully updated.");
          this.editFormVisible = false;
          this.clearForm();
        })
        .catch(() => {
          ElMessage.error("Error fetching data from the server.");
        });
    },
  },
  created() {
    this.getLocation();
  },
  computed: {
    ...mapGetters({
      user: "returnUserRole",
      oidcUser: "oidcStore/oidcUser",
    }),
  },
  async mounted() {
    const userID = this.oidcUser.sub;
    //  await this.$store.dispatch("getUserRole", userID);

    this.$refs.myMapRef.$mapPromise.then((mapObject) => {});
  },
});
</script>

<style lang="scss" scoped>
.item {
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.menuCard {
  min-height: 50vh;
  max-height: calc(92vh - 32px);
  overflow: hidden;
}

.tableContent {
  max-height: calc(82vh - 32px);
  overflow: auto;
}

.bodyContent {
  padding-top: 0.5rem;
}
.newButton {
  text-align: end;
}
</style>
