<template>
  <div>
    <!-- Body Card -->
    <el-card shadow="hover" class="menuCard">
      <!-- Top Level Menu -->
      <el-row class="item">
        <el-col class="titleHeader" :span="3">
          <div
            :style="`font-size: var(--el-font-size-extra-large); text-align: left`"
          >
            Inquiries
          </div>
        </el-col>
        <el-col :span="6" class="text-right">
          <div class="newButton">
            <el-button type="primary" @click="postInquiryVisible = true">
              Inquiry Test
            </el-button>
            <el-button
              @click="handleShowHiddenData('showHidden')"
              v-show="!hiddenButton"
            >
              Show Hidden Data
            </el-button>
            <el-button
              @click="handleShowHiddenData('showUnhidden')"
              v-show="hiddenButton"
            >
              Unshow Hidden Data
            </el-button>
          </div>
        </el-col>
      </el-row>
      <!-- Top Level Menu -->

      <!-- Content -->
      <el-skeleton :loading="loading" animated :count="1" :throttle="500">
        <template #default>
          <div>
            <!-- Insert Grid here -->
            <div v-if="errorLoading === false">
              <!-- Display Control Area -->
              <el-row>
                <!-- Display Controls -->
              </el-row>
              <!-- Display Control Area -->

              <!-- Grid or Table  Add Logic on DIV-->
              <div class="bodyContent">
                <!-- Grid or Table  Empty Data Logic Handler on DIV -->
                <div class="tableContent">
                  <el-scrollbar always>
                    <el-table
                      :data="listOfInquiries"
                      v-loading="loading"
                      style="width: 100%"
                    >
                      <el-table-column label="Email" prop="senderEmail" />
                      <el-table-column
                        prop="isReplied"
                        label="Status"
                        width="100"
                        :filters="[
                          { text: 'Replied', value: true },
                          { text: 'Not Replied', value: false },
                        ]"
                        :filter-method="filterTag"
                        filter-placement="bottom-end"
                      >
                        <template #default="scope">
                          <el-tag
                            :type="
                              scope.row.isReplied === 'true' ? '' : 'success'
                            "
                            disable-transitions
                          >
                            {{ getTagText(scope.row.isReplied) }}</el-tag
                          >
                        </template>
                      </el-table-column>
                      <el-table-column align="right">
                        <template #default="scope">
                          <el-button
                            size="small"
                            @click="handleShowDetails(scope.row)"
                            >Show Details</el-button
                          >
                          <el-button
                            v-show="hiddenButton"
                            size="small"
                            @click="handleAction('unhide', scope.row)"
                            >Unhide</el-button
                          >
                          <el-button
                            v-show="!hiddenButton"
                            size="small"
                            @click="handleAction('hide', scope.row)"
                            >Hide</el-button
                          >
                          <el-button
                            size="small"
                            type="danger"
                            @click="handleAction('delete', scope.row)"
                            >Delete</el-button
                          >
                        </template>
                      </el-table-column>
                    </el-table>
                    <!-- Main Content  -->
                  </el-scrollbar>
                </div>
              </div>
              <!-- Grid or Table -->

              <!-- Emptry State Summary -->
              <!-- Emptry State Summary -->
            </div>

            <!-- Error Loading Empty state -->
            <el-empty description="Error loading " v-else>
              <el-button>Reload Table</el-button>
            </el-empty>
            <!-- Error Loading Empty state -->
          </div>
        </template>
      </el-skeleton>
      <!-- Content -->
    </el-card>

    <!-- Dialog -->
    <!-- Post Inquiry Dialog -->
    <el-dialog
      v-model="postInquiryVisible"
      title="Post Inquiry"
      @keydown.esc="clearForm()"
    >
      <el-form :model="form" :label-position="labelPosition">
        <el-form-item label="Name " :label-width="formLabelWidth">
          <el-input v-model="form.name"> </el-input>
        </el-form-item>
        <el-form-item label="Email " :label-width="formLabelWidth">
          <el-input v-model="form.email"> </el-input>
        </el-form-item>
        <el-form-item label="Message " :label-width="formLabelWidth">
          <el-input v-model="form.message"> </el-input>
        </el-form-item>
        <el-form-item label="Subject " :label-width="formLabelWidth">
          <el-input v-model="form.subject"> </el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="clearForm()">Cancel</el-button>
          <el-button type="primary" @click="postInquiry()">Confirm</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- Post Inquiry Dialog -->

    <!-- Delete Dialog -->
    <el-dialog v-model="deleteDialogVisible" title="Warning" width="30%" center>
      <span text-align="center"
        >Are you sure you want to delete this data?</span
      >
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="deleteDialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="performDelete()">Confirm</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- Delete Dialog -->

    <!-- Action Dialog -->
    <el-dialog v-model="actionDialogVisible" title="Warning" width="30%" center>
      <span text-align="center"
        >Are you sure you want to {{ actionType }} this data?</span
      >
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="actionDialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="performAction()">Confirm</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- Action Dialog -->

    <!-- Show Details Dialog -->
    <el-dialog
      v-model="showDetailsVisible"
      title="Inquiry Details"
      @close="clearForm()"
      @keydown.esc="clearForm()"
    >
      <el-form :model="form" :label-position="labelPosition">
        <el-form-item
          label="Inquirer Name:"
          :label-width="formLabelWidth"
          style="font-size: 20px; font-weight: bold"
        >
          <span style="font-size: 16px; font-weight: normal"
            >{{ form.name }}
          </span>
        </el-form-item>
        <el-form-item
          label="Inquirer Email:"
          :label-width="formLabelWidth"
          style="font-size: 20px; font-weight: bold"
        >
          <span style="font-size: 16px; font-weight: normal"
            >{{ form.senderEmail }}
          </span>
        </el-form-item>
        <el-form-item
          label="Subject:"
          :label-width="formLabelWidth"
          style="font-size: 20px; font-weight: bold"
        >
          <span style="font-size: 16px; font-weight: normal"
            >{{ form.subject }}
          </span>
        </el-form-item>
        <el-form-item
          label="Message:"
          :label-width="formLabelWidth"
          style="font-size: 20px; font-weight: bold"
        >
          <div
            v-html="form.message"
            style="font-size: 16px; font-weight: normal"
          ></div>
        </el-form-item>
        <el-form-item
          label="Replied Message"
          :label-width="formLabelWidth"
          style="font-size: 20px; font-weight: bold"
          v-show="selectedRow.isReplied"
        >
          <div
            v-html="form.replyMessage"
            style="font-size: 16px; font-weight: normal"
          ></div>
        </el-form-item>
        <el-form-item
          label="Reply Message"
          :label-width="formLabelWidth"
          style="font-size: 20px; font-weight: bold"
          v-show="showInput"
        >
          <editor
            v-model="form.replyMessage"
            api-key="eiy2zuc18690mjmbkruhdctjlhtu9vw9xwgergsqxarrcewk"
            :init="editorConfig"
          ></editor>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="clearForm()" v-show="!showInput">Close</el-button>
          <el-button
            @click="handleReply(form)"
            v-show="!selectedRow.isReplied && !showInput"
            >Reply</el-button
          >
          <el-button @click="replyInquiry()" v-show="showInput"
            >Confirm</el-button
          >
          <el-button @click="showInput = false" v-show="showInput"
            >Cancel</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- Show Details Dialog -->
    <!-- Dialog -->
  </div>
</template>

<script>
/* global tinymce */
import { defineComponent, reactive, ref } from "vue";
import api from "@/services/apiService";
import { ElMessage } from "element-plus";
import { mapGetters } from "vuex";
import Editor from "@tinymce/tinymce-vue";

export default defineComponent({
  name: "Inquiries",
  components: {
    editor: Editor,
  },
  data() {
    return {
      loading: false,
      errorLoading: false,
      contactInfoRecipientEmail: "",
      listOfInquiries: null,
      postInquiryVisible: false,
      replyDialogVisible: false,
      deleteDialogVisible: false,
      showPreviewVisible: false,
      deleteType: "",
      selectedPreview: "",
      hiddenButton: false,
      showDetailsVisible: false,
      showInput: false,
      selectedRow: "",
      labelPosition: ref("top"),
      actionDialogVisible: false,

      form: reactive({
        inquiryId: "",
        recipientEmail: "",
        name: "",
        senderEmail: "",
        message: "",
        subject: "",
        replyMessage: "",
      }),

      editorConfig: {
        height: 500,
        width: 2000,
        menubar: true,
        plugins: ["image", "code", "table", "link", "media", "codesample"],
        toolbar:
          "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat | quickimage",
        a11y_advanced_options: true,
        image_title: true,
        automatic_uploads: true,
        file_picker_types: "image,media",

        file_picker_callback: (cb) => {
          var input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");

          input.onchange = function () {
            var file = this.files[0];

            var reader = new FileReader();
            reader.onload = function () {
              var id = "blobid" + new Date().getTime();
              var blobCache = tinymce.activeEditor.editorUpload.blobCache;
              var base64 = reader.result.split(",")[1];
              var blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);

              cb(blobInfo.blobUri(), { title: file.name });
            };
            reader.readAsDataURL(file);
          };

          input.click();
        },
      },
    };
  },
  computed: {
    getTagText() {
      return function (isReplied) {
        return isReplied ? "Replied" : "Not Replied";
      };
    },
    ...mapGetters({
      user: "returnUserRole",
      oidcUser: "oidcStore/oidcUser",
    }),
  },

  methods: {
    handleShowHiddenData(action) {
      if (action === "showHidden") {
        this.getHiddenInquiry();
        this.hiddenButton = true;
      } else if (action === "showUnhidden") {
        this.getInquiry();
        this.hiddenButton = false;
      }
    },
    handleShowDetails(row) {
      this.showDetailsVisible = true;
      this.selectedRow = row;
      this.form = row;
    },
    handleAction(action, row) {
      this.actionDialogVisible = true;
      this.actionType = action;
      this.form.inquiryId = row.inquiryId;
    },
    performAction() {
      if (this.actionType === "delete") {
        this.deleteInquiry();
      } else if (this.actionType === "hide") {
        this.hideInquiry();
      } else if (this.actionType === "unhide") {
        this.unhideInquiry();
      }
    },

    filterTag(value, row) {
      return row.isReplied === value;
    },
    async clearForm() {
      this.form = {
        inquiryId: "",
        recipientEmail: "",
        name: "",
        senderEmail: "",
        message: "",
        password: "",
      };
      this.postInquiryVisible = false;
      this.showDetailsVisible = false;
      this.showInput = false;
    },
    async getContactInfo() {
      await api
        .get("/ContactInfo")
        .then((response) => {
          this.listOfContactInfo = response.data;
          this.contactInfoRecipientEmail =
            this.listOfContactInfo[0].contactInfoId;
        })
        .catch((error) => {
          console.error(error);
          ElMessage.error("Failed to retrieve data from the server.");
        });
    },

    handleReply(row) {
      this.showInput = true;
      this.form.inquiryId = row.inquiryId;
    },

    async replyInquiry() {
      await api
        .patch(`Inquiry/Reply/${this.form.inquiryId}`, {
          ...this.form,
        })
        .then(() => {
          ElMessage.success("Replied!");
          this.replyDialogVisible = false;
          this.clearForm();
          this.getInquiry();
        })
        .catch(() => {
          ElMessage.error("error");
        });
    },

    async hideInquiry() {
      await api
        .patch(`Inquiry/Hide/${this.form.inquiryId}`, {
          ...this.form,
        })
        .then(() => {
          ElMessage.success("Data hidden.");
          this.actionDialogVisible = false;
          this.clearForm();
          this.getInquiry();
        })
        .catch(() => {
          ElMessage.error("Error hiding data.");
        });
    },

    async unhideInquiry() {
      await api
        .patch(`Inquiry/Unhide/${this.form.inquiryId}`, {
          ...this.form,
        })
        .then(() => {
          ElMessage.success("Data unhidden.");
          this.actionDialogVisible = false;
          this.clearForm();
          this.getInquiry();
        })
        .catch(() => {
          ElMessage.error("Error unhiding data.");
        });
    },

    async getInquiry() {
      this.loading = true;
      await api.get("/Inquiry").then((response) => {
        this.listOfInquiries = response.data;
      });
      this.loading = false;
    },

    async getHiddenInquiry() {
      this.loading = true;
      await api.get("/Inquiry/Hidden").then((response) => {
        this.listOfInquiries = response.data;
      });
      this.loading = false;
    },

    async postInquiry() {
      const payload = {
        name: this.form.name,
        message: this.form.message,
        senderEmail: this.form.email,
        subject: this.form.subject,
        contactInfoId: this.contactInfoRecipientEmail,
      };
      try {
        await api.post("/Inquiry/", payload);
        this.postInquiryVisible = false;
        await this.clearForm();
        ElMessage.success("Inquiry successfully sent.");
      } catch (error) {
        ElMessage.error("Error uploading data to the server.");
      }
    },

    async deleteInquiry() {
      await api.delete(`Inquiry/${this.form.inquiryId}`).then(() => {
        this.actionDialogVisible = false;
        ElMessage.success("Data successfully deleted!");
        this.clearForm();
        if (this.hiddenButton) {
          this.getHiddenInquiry();
        } else {
          this.getInquiry();
        }
      });
    },
  },
  created() {
    this.getContactInfo();
    this.getInquiry();
  },

  async mounted() {
    const userID = this.oidcUser.sub;
    //  await this.$store.dispatch("getUserRole", userID);
  },
});
</script>

<style>
.my-autocomplete {
  width: 100%;
}

.pac-container {
  z-index: 9999;
  position: absolute;
  width: 100%;
}

.tox .tox-notification--error {
  display: none !important;
}

.tox-tinymce-aux {
  z-index: 5000 !important;
}

.item {
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.menuCard {
  min-height: 50vh;
  max-height: calc(92vh - 32px);
  overflow: hidden;
}

.tableContent {
  max-height: calc(82vh - 32px);
  overflow: auto;
}

.bodyContent {
  padding-top: 0.5rem;
}
.newButton {
  text-align: end;
}
</style>
