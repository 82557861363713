<template>
  <div>
    <!-- Body Card -->
    <el-card shadow="hover" class="menuCard">
      <!-- Top Level Menu -->
      <el-row class="item">
        <el-col class="titleHeader" :span="3">
          <div
            :style="`font-size: var(--el-font-size-extra-large); text-align: left`"
          >
            About Skanlog
          </div>
        </el-col>
      </el-row>
      <!-- Top Level Menu -->

      <!-- Content -->
      <el-skeleton :loading="loading" animated :count="1" :throttle="500">
        <template #default>
          <div>
            <!-- Insert Grid here -->
            <div v-if="errorLoading === false">
              <!-- Display Control Area -->
              <el-row>
                <!-- Display Controls -->
              </el-row>
              <!-- Display Control Area -->

              <!-- Grid or Table  Add Logic on DIV-->
              <div class="bodyContent">
                <!-- Grid or Table  Empty Data Logic Handler on DIV -->
                <div class="tableContent">
                  <el-scrollbar always>
                    <el-table
                      :data="listOfAboutSkanlog"
                      style="width: 100%"
                      v-loading="loading"
                    >
                      <el-table-column label="Type" prop="type" />
                      <el-table-column align="right">
                        <template #default="scope">
                          <el-button
                            @click="handlePreview(scope.row)"
                            size="small"
                          >
                            Preview
                          </el-button>
                          <el-button size="small" @click="handleEdit(scope.row)"
                            >Edit</el-button
                          >
                        </template>
                      </el-table-column>
                    </el-table>
                    <!-- Main Content  -->
                  </el-scrollbar>
                </div>
              </div>
              <!-- Grid or Table -->

              <!-- Emptry State Summary -->
              <!-- Emptry State Summary -->
            </div>

            <!-- Error Loading Empty state -->
            <el-empty description="Error loading " v-else>
              <el-button>Reload Table</el-button>
            </el-empty>
            <!-- Error Loading Empty state -->
          </div>
        </template>
      </el-skeleton>
      <!-- Content -->
    </el-card>

    <!-- Dialog -->
    <!-- Edit Form Dialog -->
    <el-dialog
      v-model="editFormVisible"
      :title="'Edit ' + form.type"
      width="90rem"
    >
      <el-form :model="form" :label-position="labelPosition">
        <el-form-item label="Type:" style="width: 20%; text-align: center">
          <el-input v-model="form.type" disabled />
        </el-form-item>

        <el-form-item label="Content:">
          <editor
            v-model="form.content"
            api-key="eiy2zuc18690mjmbkruhdctjlhtu9vw9xwgergsqxarrcewk"
            :init="editorConfig"
          ></editor>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="clearForm()">Cancel</el-button>
          <el-button type="primary" @click="editAbout()">Confirm</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- Edit Form Dialog -->

    <!-- Content Preview Dialog -->
    <el-dialog
      :title="contentType"
      v-model="showPreviewVisible"
      :class="'preview-dialog'"
    >
      <div v-html="selectedPreview"></div>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="showPreviewVisible = false">Close</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- Content Preview Dialog -->
    <!-- Dialog -->
  </div>

  <div v-show="!user.isGranted">
    You have no permission to access this page. Please contact page
    administrator.
  </div>
</template>

<script>
/*global tinymce */
import { defineComponent, reactive, ref } from "vue";
import api from "@/services/apiService";
import { ElMessage } from "element-plus";
import Editor from "@tinymce/tinymce-vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "AboutSkanlog",
  components: {
    editor: Editor,
  },
  data() {
    return {
      loading: true,
      errorLoading: false,
      listOfAboutSkanlog: null,
      editFormVisible: false,
      centerDialogVisible: false,
      showPreviewVisible: false,
      selectedPreview: "",
      labelPosition: ref("top"),
      contentType: "",
      selectedRow: "",

      form: reactive({
        aboutSkanlogId: null,
        content: "",
        type: "",
      }),

      editorConfig: {
        height: 500,
        width: 2000,
        menubar: true,
        plugins: ["image", "code", "table", "link", "media", "codesample"],
        toolbar:
          "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat | quickimage",
        a11y_advanced_options: true,
        image_title: true,
        automatic_uploads: true,
        file_picker_types: "image,media",

        file_picker_callback: (cb) => {
          var input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");

          input.onchange = function () {
            var file = this.files[0];

            var reader = new FileReader();
            reader.onload = function () {
              var id = "blobid" + new Date().getTime();
              var blobCache = tinymce.activeEditor.editorUpload.blobCache;
              var base64 = reader.result.split(",")[1];
              var blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);

              cb(blobInfo.blobUri(), { title: file.name });
            };
            reader.readAsDataURL(file);
          };

          input.click();
        },
      },
    };
  },
  methods: {
    handlePreview(row) {
      this.selectedPreview = row.content;
      this.contentType = row.type;
      this.showPreviewVisible = true;
    },
    clearForm() {
      this.editFormVisible = false;
      this.form = {
        aboutSkanlogId: null,
        content: "",
        type: "",
      };
    },

    handleFilePick: function (cb) {
      var input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");

      input.onchange = function () {
        var file = this.files[0];

        var reader = new FileReader();
        reader.onload = function () {
          var id = "blobid" + new Date().getTime();
          var blobCache = tinymce.activeEditor.editorUpload.blobCache;
          var base64 = reader.result.split(",")[1];
          var blobInfo = blobCache.create(id, file, base64);
          blobCache.add(blobInfo);

          /* call the callback and populate the Title field with the file name */
          cb(blobInfo.blobUri(), { title: file.name });
        };
        reader.readAsDataURL(file);
      };

      input.click();
    },

    async getAbout() {
      this.loading = true;
      await api
        .get("/AboutSkanlog")
        .then((response) => {
          this.listOfAboutSkanlog = response.data;
        })
        .catch((error) => {
          ElMessage.error("Failed to retrieve data from the server.");
          console.error(error);
        });
      this.loading = false;
    },

    handleEdit(row) {
      this.editFormVisible = true;
      this.form.aboutSkanlogId = row.aboutSkanlogId;
      this.form.type = row.type;
      this.form.content = row.content;
    },

    async editAbout() {
      await api
        .put(`AboutSkanlog/${this.form.aboutSkanlogId}`, {
          aboutSkanlogId: this.form.aboutSkanlogId,
          content: this.form.content,
          type: this.form.type,
        })
        .then(() => {
          this.editFormVisible = false;
          ElMessage.success("Data successfully updated.");
          this.getAbout();
          this.clearForm();
        })
        .catch(() => {
          ElMessage.error("Error updating data.");
        });
    },
  },
  created() {
    this.getAbout();
  },
  async mounted() {
    const userID = this.oidcUser.sub;
    //  await this.$store.dispatch("getUserRole", userID);
  },

  computed: {
    ...mapGetters({
      user: "returnUserRole",
      oidcUser: "oidcStore/oidcUser",
    }),
  },
});
</script>

<style>
.tox .tox-notification--error {
  display: none !important;
}

.tox-tinymce-aux {
  z-index: 5000 !important;
}

.item {
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.menuCard {
  min-height: 50vh;
  max-height: calc(92vh - 32px);
  overflow: hidden;
}

.tableContent {
  max-height: calc(82vh - 32px);
  overflow: auto;
}

.bodyContent {
  padding-top: 0.5rem;
}
.newButton {
  text-align: end;
}
</style>
