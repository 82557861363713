<template>
  <div v-if="user.isGranted">
    <!-- Body Card -->
    <el-card shadow="hover" class="menuCard">
      <!-- Top Level Menu -->
      <el-row class="item">
        <el-col class="titleHeader" :span="3">
          <div
            :style="`font-size: var(--el-font-size-extra-large); text-align: left`"
          >
            Career
          </div>
        </el-col>
        <el-col :span="5" class="text-right">
          <div class="newButton">
            <el-button type="primary" @click="createCareerVisible = true">
              Add
            </el-button>
            <el-button
              @click="handleShowHiddenData('showHidden')"
              v-show="!hiddenButton"
            >
              Show Hidden Data
            </el-button>
            <el-button
              @click="handleShowHiddenData('showUnhidden')"
              v-show="hiddenButton"
            >
              Unshow Hidden Data
            </el-button>
          </div>
        </el-col>
      </el-row>
      <!-- Top Level Menu -->

      <!-- Content -->
      <el-skeleton :loading="loading" animated :count="1" :throttle="500">
        <template #default>
          <div>
            <!-- Insert Grid here -->
            <div v-if="errorLoading === false">
              <!-- Display Control Area -->
              <el-row>
                <!-- Display Controls -->
              </el-row>
              <!-- Display Control Area -->

              <!-- Grid or Table  Add Logic on DIV-->
              <div class="bodyContent">
                <!-- Grid or Table  Empty Data Logic Handler on DIV -->
                <div class="tableContent">
                  <el-scrollbar always>
                    <el-table
                      :data="listOfCareer"
                      style="width: 100%"
                      v-loading="loading"
                    >
                      <el-table-column
                        label="Career Position"
                        prop="careerName"
                      />
                      <el-table-column align="right">
                        <template #default="scope">
                          <el-button
                            size="small"
                            @click="handleShowDetails(scope.row)"
                            >Show Details</el-button
                          >
                          <el-button
                            v-show="hiddenButton"
                            size="small"
                            @click="handleAction('unhide', scope.row)"
                            >Unhide</el-button
                          >
                          <el-button
                            v-show="!hiddenButton"
                            size="small"
                            @click="handleAction('hide', scope.row)"
                            >Hide</el-button
                          >
                          <el-button
                            size="small"
                            type="danger"
                            @click="handleAction('delete', scope.row)"
                            >Delete</el-button
                          >
                        </template>
                      </el-table-column>
                    </el-table>
                    <!-- Main Content  -->
                  </el-scrollbar>
                </div>
              </div>
              <!-- Grid or Table -->

              <!-- Emptry State Summary -->
              <!-- Emptry State Summary -->
            </div>

            <!-- Error Loading Empty state -->
            <el-empty description="Error loading " v-else>
              <el-button>Reload Table</el-button>
            </el-empty>
            <!-- Error Loading Empty state -->
          </div>
        </template>
      </el-skeleton>
      <!-- Content -->
    </el-card>

    <!-- Dialog -->
    <!-- Create / Edit Form Dialog -->
    <el-dialog
      v-model="createCareerVisible"
      title="Enter Description"
      width="90rem"
      @keydown.esc="clearForm()"
      @close="clearForm()"
    >
      <el-form :model="form" :label-position="labelPosition">
        <el-form-item label="Career Position:" :label-width="formLabelWidth">
          <el-input v-model="form.careerName" />
        </el-form-item>
        <el-form-item label="Job Overview:" :label-width="formLabelWidth">
          <editor
            v-model="form.careerOverview"
            api-key="eiy2zuc18690mjmbkruhdctjlhtu9vw9xwgergsqxarrcewk"
            :init="editorConfig"
          ></editor>
        </el-form-item>

        <el-form-item label="Job Description:" :label-width="formLabelWidth">
          <editor
            v-model="form.careerDescription"
            api-key="eiy2zuc18690mjmbkruhdctjlhtu9vw9xwgergsqxarrcewk"
            :init="editorConfig"
          ></editor>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="clearForm()">Cancel</el-button>
          <el-button type="primary" @click="addCareer(form)">Confirm</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- Create / Edit Form Dialog -->

    <!-- Action Dialog -->
    <el-dialog v-model="actionDialogVisible" title="Warning" width="30%" center>
      <span text-align="center"
        >Are you sure you want to {{ actionType }} this data?</span
      >
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="actionDialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="performAction()">Confirm</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- Action Dialog -->

    <!-- Show Details Dialog -->
    <el-dialog
      v-model="showDetailsVisible"
      :title="form.careerName + ' Details'"
      @close="clearForm()"
      @keydown.esc="clearForm()"
      width="90rem"
    >
      <el-form :model="form" :label-position="labelPosition">
        <el-form-item
          label="Position:"
          :label-width="formLabelWidth"
          style="font-size: 20px; font-weight: bold"
        >
          <span v-if="!showInput" style="font-size: 16px; font-weight: normal"
            >{{ form.careerName }}
          </span>
          <el-input v-model="form.careerName" v-show="showInput" />
        </el-form-item>
        <el-form-item
          label="Career Overview"
          :label-width="formLabelWidth"
          style="font-size: 20px; font-weight: bold"
          v-show="!showInput"
        >
          <div
            v-html="form.careerOverview"
            style="font-size: 16px; font-weight: normal"
          ></div>
        </el-form-item>
        <el-form-item
          label="Career Overview"
          :label-width="formLabelWidth"
          style="font-size: 20px; font-weight: bold"
          v-show="showInput"
        >
          <editor
            v-model="form.careerOverview"
            api-key="eiy2zuc18690mjmbkruhdctjlhtu9vw9xwgergsqxarrcewk"
            :init="editorConfig"
          ></editor>
        </el-form-item>
        <el-form-item
          label="Career Description"
          :label-width="formLabelWidth"
          style="font-size: 20px; font-weight: bold"
          v-show="!showInput"
        >
          <div
            v-html="form.careerDescription"
            style="font-size: 16px; font-weight: normal"
          ></div>
        </el-form-item>
        <el-form-item
          label="Career Description"
          :label-width="formLabelWidth"
          style="font-size: 20px; font-weight: bold"
          v-show="showInput"
        >
          <editor
            v-model="form.careerDescription"
            api-key="eiy2zuc18690mjmbkruhdctjlhtu9vw9xwgergsqxarrcewk"
            :init="editorConfig"
          ></editor>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="clearForm()" v-show="!showInput">Close</el-button>
          <el-button @click="handleEdit(form)" v-show="!showInput"
            >Edit</el-button
          >
          <el-button @click="editCareer" v-show="showInput">Confirm</el-button>
          <el-button @click="showInput = false" v-show="showInput"
            >Cancel</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- Show Details Dialog -->
  </div>

  <div v-else>
    You have no permission to access this page. Please contact page
    administrator.
  </div>
</template>

<script>
/* global tinymce */
import { defineComponent, reactive, ref } from "vue";
import api from "@/services/apiService";
import { ElMessage } from "element-plus";
import Editor from "@tinymce/tinymce-vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "Career",
  components: {
    editor: Editor,
  },
  data() {
    return {
      loading: false,
      errorLoading: false,
      listOfCareer: null,
      createCareerVisible: false,
      actionDialogVisible: false,
      showDetailsVisible: false,
      labelPosition: ref("top"),
      formLabelWidth: "140px",
      actionType: "",
      hiddenButton: false,
      showInput: false,

      form: reactive({
        careerId: null,
        careerDescription: "",
        careerName: "",
        careerOverview: "",
      }),

      editorConfig: {
        height: 500,
        width: 2000,
        menubar: true,
        plugins: [
          "image",
          "code",
          "table",
          "link",
          "media",
          "codesample",
          "lists",
        ],
        toolbar:
          "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat | quickimage | numlist bullist",
        a11y_advanced_options: true,
        image_title: true,
        automatic_uploads: true,
        file_picker_types: "image,media",

        file_picker_callback: (cb) => {
          var input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");

          input.onchange = function () {
            var file = this.files[0];

            var reader = new FileReader();
            reader.onload = function () {
              var id = "blobid" + new Date().getTime();
              var blobCache = tinymce.activeEditor.editorUpload.blobCache;
              var base64 = reader.result.split(",")[1];
              var blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);

              cb(blobInfo.blobUri(), { title: file.name });
            };
            reader.readAsDataURL(file);
          };

          input.click();
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "returnUserRole",
      oidcUser: "oidcStore/oidcUser",
    }),
  },
  async mounted() {
    const userID = this.oidcUser.sub;
    await this.$store.dispatch("getUserRole", userID);
  },
  methods: {
    handleShowHiddenData(action) {
      if (action === "showHidden") {
        this.getHiddenCareer();
        this.hiddenButton = true;
      } else if (action === "showUnhidden") {
        this.getCareer();
        this.hiddenButton = false;
      }
    },
    handleShowDetails(row) {
      this.showDetailsVisible = true;
      this.form = row;
    },
    handleAction(action, row) {
      this.actionDialogVisible = true;
      this.actionType = action;
      this.form.careerId = row.careerId;
    },
    performAction() {
      if (this.actionType === "delete") {
        this.deleteCareer();
      } else if (this.actionType === "hide") {
        this.hideCareer();
      } else if (this.actionType === "unhide") {
        this.unhideCareer();
      }
    },
    clearForm() {
      this.form = {
        careerId: null,
        careerDescription: "",
        careerName: "",
        careerOverview: "",
      };
      this.createCareerVisible = false;
      this.showInput = false;
      this.showDetailsVisible = false;
    },

    handleFilePick: function (cb) {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";

      input.onchange = function () {
        const file = this.files[0];
        const reader = new FileReader();

        reader.onload = function () {
          const id = "blobid" + new Date().getTime();
          const blobCache = tinymce.activeEditor.editorUpload.blobCache;
          const base64 = reader.result.split(",")[1];
          const blobInfo = blobCache.create(id, file, base64);
          blobCache.add(blobInfo);
          cb(blobInfo.blobUri(), { title: file.name });
        };

        reader.readAsDataURL(file);
      };

      input.click();
    },

    async getCareer() {
      this.loading = true;
      await api
        .get("/Career")
        .then((response) => {
          this.listOfCareer = response.data;
        })
        .catch((error) => {
          ElMessage.error("Failed to retrieve data from the server.");
          console.log(error);
        });
      this.loading = false;
    },

    async getHiddenCareer() {
      this.loading = true;
      await api
        .get("/Career/Hidden")
        .then((response) => {
          this.listOfCareer = response.data;
        })
        .catch((error) => {
          ElMessage.error("Failed to retrieve data from the server.");
          console.log(error);
        });
      this.loading = false;
    },

    async addCareer(career) {
      await api
        .post("/Career/", career)
        .then(() => {
          this.createCareerVisible = false;
          ElMessage.success("Data successfully created.");
          this.clearForm();
          this.getCareer();
        })
        .catch(() => {
          ElMessage.error("Error posting data to the server.");
        });
    },

    async handleEdit(row) {
      this.showInput = true;
      this.form.careerId = row.careerId;
      this.form.careerDescription = row.careerDescription;
      this.form.careerName = row.careerName;
      this.form.careerOverview = row.careerOverview;
    },

    async editCareer() {
      await api
        .put(`Career/${this.form.careerId}`, {
          careerId: this.form.careerId,
          careerDescription: this.form.careerDescription,
          careerName: this.form.careerName,
          careerOverview: this.form.careerOverview,
        })
        .then(() => {
          this.createCareerVisible = false;
          this.showInput = false;
          ElMessage.success("Data successfully updated.");
          this.getCareer();
        })
        .catch(() => {
          ElMessage.error("Error updating data.");
        });
    },

    async hideCareer() {
      await api
        .patch(`Career/Hide/${this.form.careerId}`)
        .then(() => {
          this.actionDialogVisible = false;
          ElMessage.success("Data successfully hidden!");
          this.getCareer();
          this.clearForm();
        })
        .catch(() => {
          ElMessage.error("Error hiding data from the server.");
        });
    },
    async unhideCareer() {
      await api
        .patch(`Career/Unhide/${this.form.careerId}`)
        .then(() => {
          this.actionDialogVisible = false;
          ElMessage.success("Data successfully unhidden!");
          this.getHiddenCareer();
          this.clearForm();
        })
        .catch(() => {
          ElMessage.error("Error hiding data from the server.");
        });
    },
    async deleteCareer() {
      await api
        .delete(`Career/${this.form.careerId}`)
        .then(() => {
          this.actionDialogVisible = false;
          ElMessage.success("Data successfully deleted!");
          this.clearForm();
          if (this.hiddenButton) {
            this.getHiddenCareer();
          } else {
            this.getCareer();
          }
        })
        .catch(() => {
          ElMessage.error("Error deleting data from the server.");
        });
    },
  },
  created() {
    this.getCareer();
  },
});
</script>

<style>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.preview-dialog {
  max-width: 1000px;
}

.preview-dialog .el-dialog__body {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tox .tox-notification--error {
  display: none !important;
}

.tox-tinymce-aux {
  z-index: 5000 !important;
}

.item {
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.menuCard {
  min-height: 50vh;
  max-height: calc(92vh - 32px);
  overflow: hidden;
}

.tableContent {
  max-height: calc(82vh - 32px);
  overflow: auto;
}

.bodyContent {
  padding-top: 0.5rem;
}
.newButton {
  text-align: end;
}
</style>
