<template>
  <div>
    <!-- Body Card -->
    <el-card shadow="hover" class="menuCard">
      <!-- Top Level Menu -->
      <el-row class="item">
        <el-col class="titleHeader" :span="3">
          <div
            :style="`font-size: var(--el-font-size-extra-large); text-align: left`"
          >
            Elev Skanlog
          </div>
        </el-col>
      </el-row>
      <!-- Top Level Menu -->

      <!-- Content -->
      <el-skeleton :loading="loading" animated :count="1" :throttle="500">
        <template #default>
          <div>
            <!-- Insert Grid here -->
            <div v-if="errorLoading === false">
              <!-- Display Control Area -->
              <el-row>
                <!-- Display Controls -->
              </el-row>
              <!-- Display Control Area -->

              <!-- Grid or Table  Add Logic on DIV-->
              <div class="bodyContent">
                <!-- Grid or Table  Empty Data Logic Handler on DIV -->
                <div class="tableContent">
                  <el-scrollbar always>
                    <el-table
                      :data="listOfElev"
                      style="width: 100%"
                      v-loading="loading"
                    >
                      <el-table-column label="Type" prop="section" />
                      <el-table-column align="right">
                        <template #default="scope">
                          <el-button size="small" @click="handleEdit(scope.row)"
                            >Edit</el-button
                          >
                          <el-button
                            size="small"
                            @click="handlePreview(scope.row)"
                          >
                            Preview
                          </el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                    <!-- Main Content  -->
                  </el-scrollbar>
                </div>
              </div>
              <!-- Grid or Table -->

              <!-- Emptry State Summary -->
              <!-- Emptry State Summary -->
            </div>

            <!-- Error Loading Empty state -->
            <el-empty description="Error loading " v-else>
              <el-button>Reload Table</el-button>
            </el-empty>
            <!-- Error Loading Empty state -->
          </div>
        </template>
      </el-skeleton>
      <!-- Content -->
    </el-card>

    <!-- Dialog -->
    <!-- Content Preview Dialog -->
    <el-dialog
      :title="rowType"
      v-model="showPreviewVisible"
      :class="'preview-dialog'"
      width="auto"
    >
      <div v-html="selectedPreview"></div>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="showPreviewVisible = false">Close</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- Content Preview Dialog -->

    <!-- Edit Form Dialog -->
    <el-dialog v-model="createElevVisible" :title="form.section" width="90rem">
      <el-form :model="form" :label-position="labelPosition">
        <el-form-item label="Section:" :label-width="formLabelWidth">
          <el-select
            v-model="form.section"
            placeholder="Select a Section"
            disabled
          >
            <el-option label="What is Elev" value="What is Elev" />
            <el-option label="Brief History" value="Brief History" />
            <el-option
              label="School Participants"
              value="School Participants"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="Content:" :label-width="formLabelWidth">
          <editor
            :init="editorConfig"
            v-model="form.content"
            api-key="eiy2zuc18690mjmbkruhdctjlhtu9vw9xwgergsqxarrcewk"
          ></editor>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="createElevVisible = false">Cancel</el-button>
          <el-button type="primary" @click="editElev()">Confirm</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- Edit Form Dialog -->
    <!-- Dialog -->
  </div>
</template>

<script>
/*global tinymce */
import { defineComponent, reactive, ref } from "vue";
import api from "@/services/apiService";
import { ElMessage } from "element-plus";
import { mapGetters } from "vuex";

import Editor from "@tinymce/tinymce-vue";

export default defineComponent({
  name: "Elev",
  components: {
    editor: Editor,
  },
  data() {
    return {
      loading: false,
      errorLoading: false,
      listOfElev: null,
      labelPosition: ref("top"),
      createElevVisible: false,
      centerDialogVisible: false,
      showPreviewVisible: false,
      rowType: "",
      appId: process.env.VUE_APP_APP_STORE_ID,

      form: reactive({
        elevId: null,
        content: "",
        section: "",
      }),

      editorConfig: {
        height: 500,
        width: 2000,
        menubar: true,
        plugins: ["image", "code", "table", "link", "media", "codesample"],
        toolbar:
          "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat | quickimage",
        a11y_advanced_options: true,
        image_title: true,
        automatic_uploads: true,
        file_picker_types: "image,media",

        file_picker_callback: (cb) => {
          var input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");

          input.onchange = function () {
            var file = this.files[0];

            var reader = new FileReader();
            reader.onload = function () {
              var id = "blobid" + new Date().getTime();
              var blobCache = tinymce.activeEditor.editorUpload.blobCache;
              var base64 = reader.result.split(",")[1];
              var blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);

              cb(blobInfo.blobUri(), { title: file.name });
            };
            reader.readAsDataURL(file);
          };

          input.click();
        },
      },
    };
  },
  async mounted() {
    const userID = this.oidcUser.sub;
    //  await this.$store.dispatch("getUserRole", userID);
  },
  computed: {
    ...mapGetters({
      user: "returnUserRole",
      oidcUser: "oidcStore/oidcUser",
    }),
  },
  methods: {
    handlePreview(row) {
      this.selectedPreview = row.content;
      this.showPreviewVisible = true;
      this.rowType = row.section;
    },
    async getElev() {
      this.loading = true;
      await api
        .get("/Elev")
        .then((response) => {
          this.listOfElev = response.data;
          console.log(this.listOfElev);
        })
        .catch((error) => {
          ElMessage.error("Failed to retrieve data from the server.");
          console.error(error);
        });
      this.loading = false;
    },

    handleEdit(row) {
      this.createElevVisible = true;
      this.form.elevId = row.elevId;
      this.form.section = row.section;
      this.form.content = row.content;
    },
    async editElev() {
      await api
        .put(`Elev/${this.form.elevId}`, {
          elevId: this.form.elevId,
          content: this.form.content,
          section: this.form.section,
          appId: this.appId,
        })
        .then(() => {
          this.createElevVisible = false;
          ElMessage.success("Data successfully updated.");
          this.getElev();
        })
        .catch(() => {
          ElMessage.error("Error updating data.");
        });
    },
  },
  created() {
    this.getElev();
  },
});
</script>

<style>
.preview-dialog {
  max-width: 1500px;
}

.tox .tox-notification--error {
  display: none !important;
}

.tox-tinymce-aux {
  z-index: 5000 !important;
}

.item {
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.menuCard {
  min-height: 50vh;
  max-height: calc(92vh - 32px);
  overflow: hidden;
}

.tableContent {
  max-height: calc(82vh - 32px);
  overflow: auto;
}

.bodyContent {
  padding-top: 0.5rem;
}
.newButton {
  text-align: end;
}
</style>
