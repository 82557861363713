<template>
  <div>
    <!-- Body Card -->
    <el-card shadow="hover" class="menuCard">
      <!-- Top Level Menu -->
      <el-row class="item">
        <el-col class="titleHeader" :span="3">
          <div
            :style="`font-size: var(--el-font-size-extra-large); text-align: left`"
          >
            Gallery
          </div>
        </el-col>
        <el-col :span="5" class="text-right">
          <div class="newButton">
            <el-button type="primary" @click="createGalleryVisible = true">
              Add
            </el-button>
            <el-button
              @click="handleShowHiddenData('showHidden')"
              v-show="!hiddenButton"
            >
              Show Hidden Data
            </el-button>
            <el-button
              @click="handleShowHiddenData('showUnhidden')"
              v-show="hiddenButton"
            >
              Unshow Hidden Data
            </el-button>
          </div>
        </el-col>
      </el-row>
      <!-- Top Level Menu -->

      <!-- Content -->
      <el-skeleton :loading="loading" animated :count="1" :throttle="500">
        <template #default>
          <div>
            <!-- Insert Grid here -->
            <div v-if="errorLoading === false">
              <!-- Display Control Area -->
              <el-row>
                <!-- Display Controls -->
              </el-row>
              <!-- Display Control Area -->

              <!-- Grid or Table  Add Logic on DIV-->
              <div class="bodyContent">
                <!-- Grid or Table  Empty Data Logic Handler on DIV -->
                <div class="tableContent">
                  <el-scrollbar always>
                    <el-table
                      :data="listOfGallery"
                      style="width: 100%"
                      v-loading="loading"
                    >
                      <el-table-column label="Album Name" prop="galleryName" />
                      <el-table-column align="right">
                        <template #default="scope">
                          <el-button
                            size="small"
                            @click="handleShowDetails(scope.row)"
                            >Show Details</el-button
                          >
                          <el-button
                            v-show="hiddenButton"
                            size="small"
                            @click="handleAction('unhide', scope.row)"
                            >Unhide</el-button
                          >
                          <el-button
                            v-show="!hiddenButton"
                            size="small"
                            @click="handleAction('hide', scope.row)"
                            >Hide</el-button
                          >
                          <el-button
                            size="small"
                            @click="handleShowImage(scope.row)"
                            >Show Images</el-button
                          >
                          <el-button
                            size="small"
                            type="danger"
                            @click="handleAction('delete', scope.row)"
                            >Delete</el-button
                          >
                        </template>
                      </el-table-column>
                    </el-table>
                    <!-- Main Content  -->
                  </el-scrollbar>
                </div>
              </div>
              <!-- Grid or Table -->

              <!-- Emptry State Summary -->
              <!-- Emptry State Summary -->
            </div>

            <!-- Error Loading Empty state -->
            <el-empty description="Error loading " v-else>
              <el-button>Reload Table</el-button>
            </el-empty>
            <!-- Error Loading Empty state -->
          </div>
        </template>
      </el-skeleton>
      <!-- Content -->
    </el-card>

    <!-- Dialog -->
    <!-- Create Gallery Dialog -->
    <el-dialog
      v-model="createGalleryVisible"
      title="Enter Gallery Details"
      @keydown.esc="clearForm()"
      @close="clearForm()"
    >
      <el-form
        :model="form"
        :label-position="labelPosition"
        v-loading="uploadLoading"
      >
        <el-form-item label="Gallery Name:" :label-width="formLabelWidth">
          <el-input v-model="form.galleryName" />
        </el-form-item>

        <el-form-item label="Gallery Date">
          <el-date-picker
            v-model="form.galleryDate"
            type="date"
            placeholder="Select date"
            :editable="false"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="Location:" :label-width="formLabelWidth">
          <el-input type="hidden" v-model="form.galleryLocation" />
          <GMapAutocomplete
            class="my-autocomplete"
            :options="autocompleteOptions"
            placeholder="Enter a location"
            @place_changed="onPlaceChanged"
            :value="form.galleryLocation"
          />
        </el-form-item>

        <el-form-item label="Brief Description">
          <el-input
            type="textarea"
            :rows="5"
            :cols="30"
            v-model="form.galleryBriefDescription"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="Upload Images / Videos:"
          :label-width="formLabelWidth"
        >
          <el-upload
            v-model:file-list="listOfImages"
            class="upload-demo"
            :action="`${apiUrl}/Files/UploadPhoto`"
            method="get"
            :data="{ galleryId: form.galleryId }"
            :headers="{ 'Content-Type': 'multipart/form-data' }"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            list-type="text"
            multiple
            :before-upload="beforeUpload"
          >
            <el-button type="primary">Click to upload</el-button>
            <template #tip>
              <div class="el-upload__tip">
                jpg/png files with a size less than 500kb
              </div>
            </template>
          </el-upload>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="clearForm()">Cancel</el-button>
          <el-button type="primary" @click="addGallery(form)"
            >Confirm</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- Create Gallery Dialog -->

    <!-- Upload Image Dialog -->
    <el-dialog
      v-model="uploadImageVisible"
      :title="'Upload Images / Videos To ' + form.galleryName"
      @keydown.esc="clearForm()"
      @close="clearForm()"
    >
      <el-form :model="form" :label-position="labelPosition">
        <el-form-item
          label="Upload Images / Videos:"
          :label-width="formLabelWidth"
        >
          <el-upload
            v-model:file-list="listOfImages"
            class="upload-demo"
            :action="`${apiUrl}/Files/UploadPhoto`"
            method="get"
            :data="{ galleryId: form.galleryId }"
            :headers="{ 'Content-Type': 'multipart/form-data' }"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            list-type="text"
            multiple
            :before-upload="beforeUpload"
            v-loading="uploadLoading"
          >
            <el-button type="primary">Click to upload</el-button>
            <template #tip>
              <div class="el-upload__tip">
                jpg/png files with a size less than 500kb
              </div>
            </template>
          </el-upload>
        </el-form-item>
        <el-form-item label="Width:" :label-width="formLabelWidth">
          <el-input v-model="form.width" />
        </el-form-item>
        <el-form-item label="height:" :label-width="formLabelWidth">
          <el-input v-model="form.height" />
        </el-form-item>
        <el-form-item :label-width="formLabelWidth">
          <el-checkbox
            v-model="withThumbnail"
            label="Generate thumbnail (thumbnail will be generated to all files)"
            size="large"
          />
        </el-form-item>
        <el-form-item
          label="Thumbnail Width:"
          :label-width="formLabelWidth"
          v-show="form.withThumbnail"
        >
          <el-input v-model="form.thumbnailWidth" />
        </el-form-item>
        <el-form-item
          label="Thumbnail Height:"
          :label-width="formLabelWidth"
          v-show="form.withThumbnail"
        >
          <el-input v-model="form.thumbnailHeight" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="clearForm()">Cancel</el-button>
          <el-button type="primary" @click="uploadFiles(this.form.galleryId)">
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- Upload Image Dialog -->

    <!-- Action Dialog -->
    <el-dialog v-model="actionDialogVisible" title="Warning" width="30%" center>
      <span text-align="center" v-if="actionType === 'file'"
        >Are you sure you want to delete this data?</span
      >
      <span text-align="center" v-else
        >Are you sure you want to {{ actionType }} this data?</span
      >

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="actionDialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="performAction()">Confirm</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- Action Dialog -->

    <!-- Display Image Dialog -->
    <el-dialog
      v-model="showImageVisible"
      :title="form.galleryName + ' Uploaded Images / Videos'"
      @close="clearForm()"
      @keydown.esc="clearForm()"
    >
      <el-table :data="listOfFiles" v-loading="loading">
        <el-table-column label="Image / Video">
          <template #default="{ row }">
            <div v-if="row.contentType.startsWith('image')">
              <img :src="acquireFileApi + row.filePath" />
            </div>
            <div v-else-if="row.contentType.startsWith('video')">
              <video
                :src="acquireFileApi + row.filePath"
                controls
                width="500"
                height="500"
              ></video>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="right">
          <template #header>
            <el-button size="small" @click="handleUpload(this.form.galleryId)"
              >Upload</el-button
            >
          </template>
          <template #default="scope">
            <el-button
              size="small"
              type="danger"
              @click="handleAction('file', scope.row)"
              >Delete</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="clearForm()">Close</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- Display Image Dialog -->

    <!-- Show Details Dialog -->
    <el-dialog
      v-model="showDetailsVisible"
      :title="form.galleryName + ' Details'"
      @close="clearForm()"
      @keydown.esc="clearForm()"
    >
      <el-form-item label="Name:" :label-width="formLabelWidth">
        <span v-if="!showInput">{{ form.galleryName }}</span>
        <el-input v-model="form.galleryName" v-show="showInput" />
      </el-form-item>
      <el-form-item label="Date:" :label-width="formLabelWidth"
        ><span v-if="!showInput">{{ form.galleryDate }}</span>
        <el-date-picker
          v-model="form.galleryDate"
          v-if="showInput"
          type="date"
          placeholder="Select date"
          :editable="false"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="Location:" :label-width="formLabelWidth">
        <span v-if="!showInput">{{ form.galleryLocation }}</span>
        <el-input
          type="hidden"
          v-model="form.galleryLocation"
          v-show="showInput"
        />
        <GMapAutocomplete
          class="my-autocomplete"
          :options="autocompleteOptions"
          placeholder="Enter a location"
          @place_changed="onPlaceChanged"
          :value="form.galleryLocation"
          v-show="showInput"
        />
      </el-form-item>
      <el-form-item label="Brief Description:" :label-width="formLabelWidth"
        ><span v-if="!showInput">{{ form.galleryBriefDescription }}</span>
        <el-input
          type="textarea"
          :rows="5"
          :cols="30"
          v-model="form.galleryBriefDescription"
          v-show="showInput"
      /></el-form-item>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="clearForm()" v-show="!showInput">Close</el-button>
          <el-button @click="handleEdit(form)" v-show="!showInput"
            >Edit</el-button
          >
          <el-button @click="editGallery" v-show="showInput">Confirm</el-button>
          <el-button @click="showInput = false" v-show="showInput"
            >Cancel</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- Show Details Dialog -->
    <!-- Dialog -->
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from "vue";
import api from "@/services/apiService";
import { ElMessage } from "element-plus";
import moment from "moment";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "Gallery",
  data() {
    return {
      loading: false,
      errorLoading: false,
      listOfGallery: null,
      listOfFiles: null,
      listOfImages: [],
      createGalleryVisible: false,
      actionDialogVisible: false,
      uploadImageVisible: false,
      showImageVisible: false,
      showDetailsVisible: false,
      hiddenButton: false,
      listOfGalleryFiltered: null,
      showInput: false,
      labelPosition: ref("top"),
      formLabelWidth: "140px",
      deleteType: "",
      uploadLoading: false,
      selectedRow: "",
      withThumbnail: false,

      acquireFileApi: process.env.VUE_APP_ACQUIRE_FILE_API,
      appId: process.env.VUE_APP_APP_STORE_ID,
      // apiUrl: process.env.VUE_APP_UPLOAD_FILE_API,

      form: reactive({
        //Gallery Properties
        galleryId: null,
        galleryName: "",
        galleryDate: "",
        galleryBriefDescription: "",
        galleryLocation: "",
        galleryTags: "",

        //Files properties
        fileId: null,
        filePath: "",
        width: "",
        height: "",
        // withThumbnail: false,
        thumbnailHeight: null,
        thumbnailWidth: null,
      }),

      autocompleteOptions: {
        componentRestrictions: { country: "ph" },
        fields: ["address_components", "geometry", "name", "formatted_address"],
        strictBounds: false,
        types: ["geocode", "establishment"],
      },
    };
  },
  methods: {
    handleShowHiddenData(action) {
      if (action === "showHidden") {
        this.getHiddenGallery();
        this.hiddenButton = true;
      } else if (action === "showUnhidden") {
        this.getGallery();
        this.hiddenButton = false;
      }
    },
    handleShowDetails(row) {
      this.showDetailsVisible = true;
      this.form = row;
    },
    handleAction(action, row) {
      this.actionDialogVisible = true;
      this.actionType = action;
      this.form.galleryId = row.galleryId;
      this.form.fileId = row.fileId;
    },

    beforeUpload(file) {
      if (
        file.type !== "image/jpeg" &&
        file.type !== "image/png" &&
        file.type !== "video/mp4"
      ) {
        ElMessage.error("Accepted file types are jpeg, png, mp4!");
        return false;
      } else if (file.size > 100 * 1024 * 1024) {
        ElMessage.error("File exceeded 100MB");
        return false;
      }
      return true;
    },

    performAction() {
      if (this.actionType === "delete") {
        this.deleteGallery();
      } else if (this.actionType === "file") {
        this.deleteFiles();
      } else if (this.actionType === "hide") {
        this.hideGallery();
      } else if (this.actionType === "unhide") {
        this.unhideGallery();
      }
    },

    handlePreview(file) {
      console.log(file);
    },
    handleRemove(file) {
      console.log(file);
    },
    onPlaceChanged(place) {
      if (place.formatted_address) {
        this.form.galleryLocation = place.formatted_address;
      }
      this.form.galleryLocation = place.formatted_address;
    },
    handleShowImage(row) {
      this.showImageVisible = true;
      this.form.galleryId = row.galleryId;
      this.form = row;
      this.getFiles(row.galleryId);
    },

    handleUpload(row) {
      this.uploadImageVisible = true;
      this.listOfImages = [];
      this.form.galleryId = row;
    },

    async clearForm() {
      this.createGalleryVisible = false;
      this.uploadImageVisible = false;
      this.showDetailsVisible = false;
      this.form = {
        //Gallery Properties
        galleryId: null,
        galleryName: "",
        galleryDate: "",
        galleryBriefDescription: "",
        galleryLocation: "",
        galleryTags: "",

        //Files properties
        fileId: null,
        filePath: "",
      };
      this.listOfImages = [];
      this.showInput = false;
    },

    async getGallery() {
      this.loading = true;
      await api
        .get("/Gallery")
        .then((response) => {
          this.listOfGallery = response.data.map((item) => {
            return {
              ...item,
              galleryDate: moment(item.galleryDate).format("ll"),
              createdDate: moment(item.createdDate).format("ll"),
            };
          });
        })
        .catch((error) => {
          ElMessage.error("Failed to retrieve data from the server.");
          console.log(error);
        });
      this.loading = false;
    },

    async getHiddenGallery() {
      this.loading = true;
      await api
        .get("/Gallery/Hidden")
        .then((response) => {
          this.listOfGallery = response.data.map((item) => {
            return {
              ...item,
              galleryDate: moment(item.galleryDate).format("ll"),
              createdDate: moment(item.createdDate).format("ll"),
            };
          });
        })
        .catch((error) => {
          ElMessage.error("Failed to retrieve data from the server.");
          console.log(error);
        });
      this.loading = false;
    },

    async addGallery(gallery) {
      this.uploadLoading = true;
      try {
        const response = await api.post("/Gallery/", gallery);
        const galleryId = response.data.galleryId;
        await this.uploadFiles(galleryId);
      } catch (error) {
        ElMessage.error("Error uploading data to the server.");
        this.clearForm();
      } finally {
        this.getGallery();
        this.clearForm();
        this.createGalleryVisible = false;
        this.uploadLoading = false;
        ElMessage.success("Successfully uploaded data");
      }
    },

    async getFiles(galleryId) {
      console.log("galleryId", galleryId);
      this.loading = true;
      await api
        .get(`Files/${galleryId}`)
        .then((response) => {
          this.listOfFiles = response.data;
          console.log("listOfFiles", this.listOfFiles);
          this.listOfFiles.forEach(async (file) => {
            console.log("path", file.filePath);
            const getContentType = await this.getContentType(file.filePath);
            console.log("getContentType", getContentType);
            file.contentType = getContentType;
          });
          console.log("listOfFiles", this.listOfFiles);
        })
        .catch((error) => {
          ElMessage.error("Failed to retrieve data from the server.");
          console.log(error);
        });
      this.loading = false;
    },

    async uploadFiles(galleryId) {
      console.log("galleryId", galleryId);
      const files = this.listOfImages.map((image) => image.raw); // get an array of selected files
      this.uploadLoading = true;
      const formData = new FormData();
      formData.append("galleryId", galleryId);
      formData.append("width", this.form.width);
      formData.append("height", this.form.height);
      formData.append("appId", this.appId);
      formData.append("withThumbnail", this.withThumbnail);

      if (
        this.form.thumbnailHeight != null &&
        this.form.thumbnailWidth != null
      ) {
        formData.append("thumbnailHeight", this.form.thumbnailHeight);
        formData.append("thumbnailWidth", this.form.thumbnailWidth);
      }

      files.forEach((file) => {
        formData.append("files", file);
      });

      await api
        .post("/Files/", formData)
        .then(() => {
          ElMessage.success("File/s successfully uploaded.");
          this.uploadImageVisible = false;
          this.clearForm();
          this.uploadLoading = false;
          this.getFiles(galleryId);
        })
        .catch(() => {
          ElMessage.error("Error uploading file to the server.");
          this.uploading = false;
        });
    },
    handleEdit(row) {
      this.showInput = true;
      this.form.galleryId = row.galleryId;
      this.form.galleryName = row.galleryName;
      this.form.galleryDate = new Date(row.galleryDate);
      this.form.galleryLocation = row.galleryLocation;
      this.form.galleryBriefDescription = row.galleryBriefDescription;
    },

    async editGallery() {
      await api
        .put(`Gallery/${this.form.galleryId}`, {
          galleryId: this.form.galleryId,
          galleryName: this.form.galleryName,
          galleryDate: this.form.galleryDate,
          galleryBriefDescription: this.form.galleryBriefDescription,
          galleryLocation: this.form.galleryLocation,
        })
        .then(() => {
          this.createGalleryVisible = false;
          this.showInput = false;
          this.form.galleryDate = moment(this.form.galleryDate).format(
            "YYYY-MM-DD",
          );
          ElMessage.success("Gallery Description successfully updated.");
          this.getGallery();
        })
        .catch(() => {
          ElMessage.error("Error fetching data from the server.");
        });
    },

    async deleteGallery() {
      await api
        .delete(`Gallery/${this.form.galleryId}`)
        .then(() => {
          this.actionDialogVisible = false;
          ElMessage.success("Successfully deleted!");
          this.getGallery();
          this.clearForm();
        })
        .catch(() => {
          ElMessage.error("Error fetching data from the server.");
          this.clearForm();
        });
    },

    async hideGallery() {
      await api
        .patch(`Gallery/Hide/${this.form.galleryId}`)
        .then(() => {
          this.actionDialogVisible = false;
          ElMessage.success("Successfully hidden!");
          this.getGallery();
          this.clearForm();
        })
        .catch(() => {
          ElMessage.error("Error fetching data from the server.");
          this.clearForm();
        });
    },

    async unhideGallery() {
      await api
        .patch(`Gallery/Unhide/${this.form.galleryId}`)
        .then(() => {
          this.actionDialogVisible = false;
          ElMessage.success("Successfully unhidden!");
          this.getHiddenGallery();
          this.clearForm();
        })
        .catch(() => {
          ElMessage.error("Error fetching data from the server.");
          this.clearForm();
        });
    },

    async deleteFiles() {
      await api
        .delete(`Files/${this.form.fileId}`)
        .then(() => {
          this.actionDialogVisible = false;
          ElMessage.success("Data successfully deleted.");
          this.getFiles(this.form.galleryId);
        })
        .catch(() => {
          ElMessage.error("Erro deleting data from the server.");
        });
    },

    async getContentType(filePath) {
      try {
        console.log("API", this.acquireFileApi);
        console.log("api path", this.acquireFileApi + filePath);
        const response = await api.get(`${this.acquireFileApi}${filePath}`);
        return response.headers["content-type"];
      } catch (error) {
        ElMessage.error("Failed to retrieve data from the server.");
        console.log(error);
      }
    },
  },
  created() {
    this.getGallery();
  },
  async mounted() {
    const userID = this.oidcUser.sub;
    console.log(userID);
    await this.$store.dispatch("getUserRole", userID);
  },
  computed: {
    ...mapGetters({
      user: "returnUserRole",
      oidcUser: "oidcStore/oidcUser",
    }),
  },
});
</script>

<style>
.my-autocomplete {
  position: relative;
  font-size: 14px;
  display: inline-flex;
  width: 100%;
  vertical-align: middle;
  box-sizing: border-box;
  line-height: 32px;

  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 1px 11px;
  background-color: #ffffff;
  border-radius: 4px;
  transform: translate3d(0, 0, 0);
  box-shadow: 0 0 0 1px #dcdfe6;
}

.pac-container {
  z-index: 9999;
  position: absolute;
  width: 100%;
}

.tox .tox-notification--error {
  display: none !important;
}

.tox-tinymce-aux {
  z-index: 5000 !important;
}

.item {
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.menuCard {
  min-height: 50vh;
  max-height: calc(92vh - 32px);
  overflow: hidden;
}

.tableContent {
  max-height: calc(82vh - 32px);
  overflow: auto;
}

.bodyContent {
  padding-top: 0.5rem;
}
.newButton {
  text-align: end;
}
</style>
