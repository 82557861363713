<template>
  <div>
    <!-- Body Card -->
    <el-card shadow="hover" class="menuCard">
      <!-- Top Level Menu -->
      <el-row class="item">
        <el-col class="titleHeader" :span="4">
          <div
            :style="`font-size: var(--el-font-size-extra-large); text-align: left`"
          >
            Contact Information
          </div>
        </el-col>
      </el-row>
      <!-- Top Level Menu -->

      <!-- Content -->
      <el-skeleton :loading="loading" animated :count="1" :throttle="500">
        <template #default>
          <div>
            <!-- Insert Grid here -->
            <div v-if="errorLoading === false">
              <!-- Display Control Area -->
              <el-row>
                <!-- Display Controls -->
              </el-row>
              <!-- Display Control Area -->

              <!-- Grid or Table  Add Logic on DIV-->
              <div class="bodyContent">
                <!-- Grid or Table  Empty Data Logic Handler on DIV -->
                <div class="tableContent">
                  <el-scrollbar always>
                    <!-- Contact Info Table -->
                    <el-table :data="listOfContactInfo" style="width: 100%">
                      <el-table-column
                        label="Location"
                        prop="locationId"
                        width="715px"
                      >
                        <template #default="{ row }">
                          {{ getLocationString(row.locationId) }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="Office Phone Number"
                        prop="officePhoneNumber"
                      />
                      <el-table-column
                        label="Recipient Email"
                        prop="recipientEmail"
                      />
                      <el-table-column align="right">
                        <template #default="scope">
                          <el-button size="small" @click="handleEdit(scope.row)"
                            >Edit</el-button
                          >
                        </template>
                      </el-table-column>
                    </el-table>
                    <!-- Contact Info Table -->
                    <!-- Main Content  -->
                  </el-scrollbar>
                </div>
              </div>
              <!-- Grid or Table -->

              <!-- Emptry State Summary -->
              <!-- Emptry State Summary -->
            </div>

            <!-- Error Loading Empty state -->
            <el-empty description="Error loading " v-else>
              <el-button>Reload Table</el-button>
            </el-empty>
            <!-- Error Loading Empty state -->
          </div>
        </template>
      </el-skeleton>
      <!-- Content -->
    </el-card>

    <!-- Dialog -->
    <!-- Edit Dialog -->
    <el-dialog v-model="editFormVisible" title="Address Details">
      <el-form :model="form" :label-position="labelPosition">
        <el-form-item label="Location:" :label-width="formLabelWidth">
          <el-input v-model="form.currentLocation" disabled />
          <input type="hidden" v-model="form.locationId" />
        </el-form-item>
        <el-form-item label="Recipient Email:" :label-width="formLabelWidth">
          <el-input v-model="form.recipientEmail" />
        </el-form-item>
        <el-form-item
          label="Office Phone Number:"
          :label-width="formLabelWidth"
        >
          <el-input v-model="form.officePhoneNumber" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="clearForm()">Cancel</el-button>
          <el-button
            type="primary"
            @click="
              form.contactInfoId ? editContactInfo() : addContactInfo(form)
            "
            >Confirm</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- Dialog -->
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from "vue";
import api from "@/services/apiService";
import { ElMessage } from "element-plus";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "ContactInfo",
  data() {
    return {
      loading: false,
      errorLoading: false,
      listOfContactInfo: null,
      listOfLocation: null,
      labelPosition: ref("top"),
      formLabelWidth: "140px",
      editFormVisible: false,
      location: "",

      form: reactive({
        contactInfoId: null,
        officePhoneNumber: "",
        recipientEmail: "",
        currentLocation: "",
        locationId: null,
      }),
    };
  },
  computed: {
    locationMap() {
      return this.listOfLocation.reduce((map, location) => {
        map[location.locationId] = location.address;
        return map;
      }, {});
    },
    ...mapGetters({
      user: "returnUserRole",
      oidcUser: "oidcStore/oidcUser",
    }),
  },
  methods: {
    //methods for manipulating locationId to actual location string
    getLocationString(locationId) {
      if (locationId !== undefined && locationId in this.locationMap) {
        return this.locationMap[locationId];
      } else {
        return "";
      }
    },

    //method for clearing form
    clearForm() {
      this.editFormVisible = false;
      this.form = {
        officePhoneNumber: "",
        currentLocation: this.listOfLocation[0].address,
      };
    },

    //api calls
    async getContactInfo() {
      await api
        .get("/ContactInfo")
        .then((response) => {
          this.listOfContactInfo = response.data;
        })
        .catch((error) => {
          console.error(error);
          ElMessage.error("Failed to retrieve data from the server.");
        });
    },

    async getLocation() {
      await api
        .get("/Location")
        .then((response) => {
          this.listOfLocation = response.data;

          this.form.currentLocation = this.listOfLocation[0].address;
          this.form.locationId = this.listOfLocation[0].locationId;
          this.location = this.listOfLocation[0].locationId;
        })
        .catch((error) => {
          console.error(error);
          ElMessage.error("Failed to retrieve data from the server.");
        });
    },

    handleEdit(row) {
      this.editFormVisible = true;
      this.form = {
        contactInfoId: row.contactInfoId,
        recipientEmail: row.recipientEmail,
        officePhoneNumber: row.officePhoneNumber,
      };
      this.form.currentLocation = this.getLocationString(row.locationId);
    },

    async editContactInfo() {
      await api
        .put(`ContactInfo/${this.form.contactInfoId}`, {
          contactInfoId: this.form.contactInfoId,
          locationId: this.location,
          recipientEmail: this.form.recipientEmail,
          officePhoneNumber: this.form.officePhoneNumber,
        })
        .then(() => {
          this.editFormVisible = false;
          ElMessage.success("Data successfully updated.");
          this.getContactInfo();
          this.clearForm();
        })
        .catch(() => {
          ElMessage.error("Error updating data.");
        });
    },
  },
  created() {
    this.getContactInfo();
    this.getLocation();
  },
  async mounted() {
    const userID = this.oidcUser.sub;
    //  await this.$store.dispatch("getUserRole", userID);
  },
});
</script>

<style lang="scss" scoped>
.item {
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.menuCard {
  min-height: 50vh;
  max-height: calc(92vh - 32px);
  overflow: hidden;
}

.tableContent {
  max-height: calc(82vh - 32px);
  overflow: auto;
}

.bodyContent {
  padding-top: 0.5rem;
}
.newButton {
  text-align: end;
}
</style>
