<template>
  <div v-if="user.isGranted">
    <!-- Body Card -->
    <el-card shadow="hover" class="menuCard">
      <!-- Top Level Menu -->
      <el-row class="item">
        <el-col class="titleHeader" :span="4">
          <div
            :style="`font-size: var(--el-font-size-extra-large); text-align: left`"
          >
            News And Events
          </div>
        </el-col>
        <el-col :span="5" class="text-right">
          <div class="newButton">
            <el-button
              type="primary"
              @click="createNewsAndEventsVisible = true"
            >
              Add
            </el-button>
            <el-button
              @click="handleShowHiddenData('showHidden')"
              v-show="!hiddenButton"
            >
              Show Hidden Data
            </el-button>
            <el-button
              @click="handleShowHiddenData('showUnhidden')"
              v-show="hiddenButton"
            >
              Unshow Hidden Data
            </el-button>
          </div>
        </el-col>
      </el-row>
      <!-- Top Level Menu -->

      <!-- Content -->
      <el-skeleton :loading="loading" animated :count="1" :throttle="500">
        <template #default>
          <div>
            <!-- Insert Grid here -->
            <div v-if="errorLoading === false">
              <!-- Display Control Area -->
              <el-row>
                <!-- Display Controls -->
              </el-row>
              <!-- Display Control Area -->

              <!-- Grid or Table  Add Logic on DIV-->
              <div class="bodyContent">
                <!-- Grid or Table  Empty Data Logic Handler on DIV -->
                <div class="tableContent">
                  <el-scrollbar always>
                    <el-table
                      :data="listOfNewsAndEvents"
                      style="width: 100%"
                      v-loading="loading"
                    >
                      <el-table-column label="Title" prop="title" />
                      <el-table-column align="right">
                        <template #default="scope">
                          <el-button
                            size="small"
                            @click="handleShowDetails(scope.row)"
                            >Show Details</el-button
                          >
                          <el-button
                            v-show="hiddenButton"
                            size="small"
                            @click="handleAction('unhide', scope.row)"
                            >Unhide</el-button
                          >
                          <el-button
                            v-show="!hiddenButton"
                            size="small"
                            @click="handleAction('hide', scope.row)"
                            >Hide</el-button
                          >
                          <el-button
                            size="small"
                            type="danger"
                            @click="handleAction('delete', scope.row)"
                            >Delete</el-button
                          >
                        </template>
                      </el-table-column>
                    </el-table>
                    <!-- Main Content  -->
                  </el-scrollbar>
                </div>
              </div>
              <!-- Grid or Table -->
              <!-- Emptry State Summary -->
              <!-- Emptry State Summary -->
            </div>

            <!-- Error Loading Empty state -->
            <el-empty description="Error loading " v-else>
              <el-button>Reload Table</el-button>
            </el-empty>
            <!-- Error Loading Empty state -->
          </div>
        </template>
      </el-skeleton>
      <!-- Content -->
    </el-card>

    <!-- Dialog -->
    <!-- Create / Edit Form Dialog -->
    <el-dialog
      v-model="createNewsAndEventsVisible"
      title="Enter Description"
      width="90rem"
      @keydown.esc="clearForm()"
      @close="clearForm()"
    >
      <el-form :model="form" :label-position="labelPosition">
        <el-form-item label="Type:" :label-width="formLabelWidth">
          <el-select
            v-model="form.articleType"
            placeholder="Please select a type"
          >
            <el-option label="News" value="News" />
            <el-option label="Event" value="Event" />
          </el-select>
        </el-form-item>

        <el-form-item label="Title:" :label-width="formLabelWidth">
          <el-input v-model="form.title" style="width: 250px" />
        </el-form-item>

        <el-form-item :label-width="formLabelWidth">
          <div class="block">
            <span class="demonstration">Date: </span>
            <el-date-picker
              v-model="form.date"
              type="date"
              placeholder="Select date"
              :editable="false"
            ></el-date-picker>
          </div>
        </el-form-item>

        <el-form-item label="Upload Featured Image: ">
          <el-upload
            ref="upload"
            v-model:file-list="featuredImage"
            class="upload-demo"
            action="https://skanlogphapi.siweb01.vm.aws.cloud.skanlog.com/api/NewsAndEvents/UploadPhoto"
            method="get"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            list-type="picture"
            :before-upload="beforeUpload"
            :on-exceed="handleExceed"
            :limit="1"
          >
            <el-button type="primary">Click to upload</el-button>
            <template #tip>
              <div class="el-upload__tip">
                Upload only 1 image file, new file will cover the old file.
              </div>
            </template>
          </el-upload>
        </el-form-item>

        <el-form-item label="Content:" :label-width="formLabelWidth">
          <editor
            :init="editorConfig"
            v-model="form.article"
            api-key="eiy2zuc18690mjmbkruhdctjlhtu9vw9xwgergsqxarrcewk"
          ></editor>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="clearForm()">Cancel</el-button>
          <el-button type="primary" @click="addNewsAndEvents(form)"
            >Confirm</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- Create Dialog -->

    <!-- Action Dialog -->
    <el-dialog v-model="actionDialogVisible" title="Warning" width="30%" center>
      <span text-align="center"
        >Are you sure you want to {{ actionType }} this data?</span
      >
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="actionDialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="performAction()">Confirm</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- Action Dialog -->

    <!-- Show Details Dialog -->
    <el-dialog
      v-model="showDetailsVisible"
      title="Images"
      @close="clearForm()"
      @keydown.esc="clearForm()"
      width="90rem"
    >
      <el-form :model="form" :label-position="labelPosition">
        <el-form-item
          label="Title:"
          :label-width="formLabelWidth"
          style="font-size: 20px; font-weight: bold"
        >
          <span v-if="!showInput" style="font-size: 16px; font-weight: normal"
            >{{ form.title }}
          </span>
          <el-input v-model="form.title" v-show="showInput" />
        </el-form-item>
        <el-form-item
          label="Type"
          :label-width="formLabelWidth"
          style="font-size: 20px; font-weight: bold"
        >
          <span v-if="!showInput" style="font-size: 16px; font-weight: normal"
            >{{ form.articleType }}
          </span>
          <el-select
            v-model="form.articleType"
            placeholder="Please select a type"
            v-show="showInput"
          >
            <el-option label="News" value="News" />
            <el-option label="Event" value="Event" />
          </el-select>
        </el-form-item>
        <el-form-item
          label="Date:"
          :label-width="formLabelWidth"
          style="font-size: 20px; font-weight: bold"
          ><span v-if="!showInput">{{ form.date }}</span>
          <el-date-picker
            v-model="form.date"
            v-if="showInput"
            type="date"
            placeholder="Select date"
            :editable="false"
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          label="Featured Image:"
          :label-width="formLabelWidth"
          style="font-size: 20px; font-weight: bold"
          ><img
            :src="`https://mediabinapi.siweb01.vm.aws.cloud.skanlog.com/api/FileUpload/Acquire/${form.filePath}`"
            width="250"
            height="250"
            v-show="!showInput"
          />
          <el-upload
            ref="upload"
            v-model:file-list="featuredImage"
            class="upload-demo"
            action="https://skanlogphapi.siweb01.vm.aws.cloud.skanlog.com/api/NewsAndEvents/UploadPhoto"
            method="get"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            list-type="picture"
            v-show="showInput"
            :before-upload="beforeUpload"
            :on-exceed="handleExceed"
            :limit="1"
          >
            <el-button type="primary">Click to upload</el-button>
            <template #tip>
              <div class="el-upload__tip">
                Upload only 1 image file, new file will cover the old file.
              </div>
            </template>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="Article"
          :label-width="formLabelWidth"
          style="font-size: 20px; font-weight: bold"
          v-show="!showInput"
          :label-position="labelPosition"
        >
          <div
            v-html="form.article"
            style="font-size: 16px; font-weight: normal"
          ></div>
        </el-form-item>
        <el-form-item
          label="Article"
          :label-width="formLabelWidth"
          style="font-size: 20px; font-weight: bold"
          v-show="showInput"
        >
          <editor
            v-model="form.article"
            api-key="eiy2zuc18690mjmbkruhdctjlhtu9vw9xwgergsqxarrcewk"
            :init="editorConfig"
          ></editor>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="clearForm()" v-show="!showInput">Close</el-button>
          <el-button @click="handleEdit(form)" v-show="!showInput"
            >Edit</el-button
          >
          <el-button @click="editNewsAndEvents" v-show="showInput"
            >Confirm</el-button
          >
          <el-button @click="showInput = false" v-show="showInput"
            >Cancel</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- Show Details Dialog -->
    <!-- Dialog -->
  </div>

  <div v-else>
    You have no permission to access this page. Please contact page
    administrator.
  </div>
</template>

<script>
/*global tinymce */
import { defineComponent, reactive, ref } from "vue";
import api from "@/services/apiService";
import { ElMessage } from "element-plus";
import moment from "moment";
import { mapGetters } from "vuex";

import Editor from "@tinymce/tinymce-vue";

export default defineComponent({
  name: "NewsAndEvents",
  components: {
    editor: Editor,
  },
  data() {
    return {
      loading: false,
      errorLoading: false,
      listOfNewsAndEvents: null,
      createNewsAndEventsVisible: false,
      centerDialogVisible: false,
      showPreviewVisible: false,
      labelPosition: ref("top"),
      featuredImage: [],
      actionType: "",
      hiddenButton: false,
      showInput: false,
      actionDialogVisible: false,
      showDetailsVisible: false,
      formLabelWidth: "140px",
      appId: process.env.VUE_APP_APP_STORE_ID,

      form: reactive({
        newsAndEventsId: null,
        articleType: "",
        article: "",
        date: "",
        title: "",
        filePath: "",
      }),

      editorConfig: {
        height: 500,
        width: 2000,
        menubar: true,
        plugins: ["image", "code", "table", "link", "media", "codesample"],
        toolbar:
          "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat | quickimage",
        a11y_advanced_options: true,
        image_title: true,
        automatic_uploads: true,
        file_picker_types: "image,media",

        file_picker_callback: (cb) => {
          var input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");

          input.onchange = function () {
            var file = this.files[0];

            var reader = new FileReader();
            reader.onload = function () {
              var id = "blobid" + new Date().getTime();
              var blobCache = tinymce.activeEditor.editorUpload.blobCache;
              var base64 = reader.result.split(",")[1];
              var blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);

              cb(blobInfo.blobUri(), { title: file.name });
            };
            reader.readAsDataURL(file);
          };

          input.click();
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "returnUserRole",
      oidcUser: "oidcStore/oidcUser",
    }),
  },

  async mounted() {
    const userID = this.oidcUser.sub;
    await this.$store.dispatch("getUserRole", userID);
  },
  methods: {
    handleExceed(file) {
      // Remove the first file from the file list
      this.$refs.upload.clearFiles();
      const files = file[0];
      // Add the new file to the file list
      this.$refs.upload.handleStart(files);
      ElMessage("File Replaced");
    },
    beforeUpload(file) {
      if (!file.type.startsWith("image")) {
        ElMessage.error("Only image files are accepted!");
        return false;
      }
      return true;
    },
    handlePreview(file) {
      console.log(file);
    },
    handleRemove(file) {
      console.log(file);
    },
    handleShowHiddenData(action) {
      if (action === "showHidden") {
        this.getHiddenNewsAndEvents();
        this.hiddenButton = true;
      } else if (action === "showUnhidden") {
        this.getNewsAndEvents();
        this.hiddenButton = false;
      }
    },
    handleShowDetails(row) {
      this.showDetailsVisible = true;
      this.form = row;
    },
    handleAction(action, row) {
      this.actionDialogVisible = true;
      this.actionType = action;
      this.form.newsAndEventsId = row.newsAndEventsId;
    },
    performAction() {
      if (this.actionType === "delete") {
        this.deleteNewsAndEvents();
      } else if (this.actionType === "hide") {
        this.hideNewsAndEvents();
      } else if (this.actionType === "unhide") {
        this.unhideNewsAndEvents();
      }
    },
    clearForm() {
      this.createNewsAndEventsVisible = false;
      this.form = {
        newsAndEventsId: null,
        articleType: "",
        article: "",
        date: "",
        title: "",
        filePath: "",
      };

      this.showInput = false;
      this.showDetailsVisible = false;
    },
    async getNewsAndEvents() {
      console.log("test");
      this.loading = true;
      await api
        .get("/NewsAndEvents")
        .then((response) => {
          this.listOfNewsAndEvents = response.data.map((item) => {
            return {
              ...item,
              date: moment(item.date).format("ll"),
            };
          });
          console.log("listOfNewsAndEvents", this.listOfNewsAndEvents);
        })
        .catch((error) => {
          ElMessage.error("Failed to retrieve data from the server.");
          console.log(error);
        });
      this.loading = false;
    },

    async getHiddenNewsAndEvents() {
      this.loading = true;
      await api
        .get("/NewsAndEvents/Hidden")
        .then((response) => {
          this.listOfNewsAndEvents = response.data.map((item) => {
            return {
              ...item,
              date: moment(item.date).format("ll"),
            };
          });
        })
        .catch((error) => {
          ElMessage.error("Failed to retrieve data from the server.");
          console.log(error);
        });
      this.loading = false;
    },

    async addNewsAndEvents(newsAndEvents) {
      if (this.featuredImage.length > 0) {
        const file = this.featuredImage[0].raw;
        const formData = new FormData();
        const newDate = moment(newsAndEvents.date).format("YYYY-MM-DD");
        formData.append("date", newDate);
        formData.append("appId", this.appId);
        for (const property in this.form) {
          formData.append(property, newsAndEvents[property]);
        }
        formData.append("file", file);
        await api
          .post("/NewsAndEvents/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            this.createNewsAndEventsVisible = false;
            ElMessage.success("Data successfully created.");
            this.clearForm();
            this.getNewsAndEvents();
          })
          .catch(() => {
            ElMessage.error("Error posting data to the server.");
          });
      } else {
        const formData = new FormData();
        const newDate = moment(newsAndEvents.date).format("YYYY-MM-DD");
        formData.append("date", newDate);
        formData.append("appId", this.appId);
        for (const property in this.form) {
          formData.append(property, newsAndEvents[property]);
        }
        formData.append("file", null);
        await api
          .post("/NewsAndEvents/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            this.createNewsAndEventsVisible = false;
            ElMessage.success("Data successfully created.");
            this.clearForm();
            this.getNewsAndEvents();
          })
          .catch(() => {
            ElMessage.error("Error posting data to the server.");
          });
      }
    },

    handleEdit(row) {
      this.showInput = true;
      this.form.newsAndEventsId = row.newsAndEventsId;
      this.form.articleType = row.articleType;
      this.form.article = row.article;
      this.form.date = row.date;
      this.form.title = row.title;
    },

    async editNewsAndEvents() {
      if (this.featuredImage.length > 0) {
        const file = this.featuredImage[0].raw;
        const formData = new FormData();
        for (const property in this.form) {
          formData.append(property, this.form[property]);
        }
        formData.append("file", file);
        formData.append("appId", this.appId);
        await api
          .put(`NewsAndEvents/${this.form.newsAndEventsId}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            ElMessage.success("Data successfully updated.");
            this.form.date = moment(this.form.date).format("YYYY-MM-DD");
            this.showInput = false;
            this.getNewsAndEvents();
          })
          .catch(() => {
            ElMessage.error("Failed to update data.");
          });
      } else {
        const formData = new FormData();
        for (const property in this.form) {
          formData.append(property, this.form[property]);
        }
        formData.append("file", null);
        formData.append("appId", this.appId);
        await api
          .put(`NewsAndEvents/${this.form.newsAndEventsId}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            ElMessage.success("Data successfully updated.");
            this.form.date = moment(this.form.date).format("YYYY-MM-DD");
            this.showInput = false;
            this.getNewsAndEvents();
          })
          .catch(() => {
            ElMessage.error("Failed to update data.");
          });
      }
    },

    async hideNewsAndEvents() {
      await api
        .patch(`NewsAndEvents/Hide/${this.form.newsAndEventsId}`)
        .then(() => {
          this.actionDialogVisible = false;
          ElMessage.success("Data successfully hidden!");
          this.getNewsAndEvents();
          this.clearForm();
        })
        .catch(() => {
          ElMessage.error("Error deleting data from the server.");
        });
    },

    async unhideNewsAndEvents() {
      await api
        .patch(`NewsAndEvents/Unhide/${this.form.newsAndEventsId}`)
        .then(() => {
          this.actionDialogVisible = false;
          ElMessage.success("Data successfully unhidden!");
          this.getHiddenNewsAndEvents();
          this.clearForm();
        })
        .catch(() => {
          ElMessage.error("Error deleting data from the server.");
        });
    },

    async deleteNewsAndEvents() {
      await api
        .delete(`NewsAndEvents/${this.form.newsAndEventsId}`)
        .then(() => {
          this.actionDialogVisible = false;
          ElMessage.success("Data successfully deleted!");
          this.clearForm();
          if (this.hiddenButton) {
            this.getHiddenNewsAndEvents();
          } else {
            this.getNewsAndEvents();
          }
        })
        .catch(() => {
          ElMessage.error("Error deleting data from the server.");
        });
    },
  },
  created() {
    this.getNewsAndEvents();
  },
});
</script>

<style>
.preview-dialog {
  max-width: 1500px;
}
.tox .tox-notification--error {
  display: none !important;
}

.tox-tinymce-aux {
  z-index: 5000 !important;
}

.item {
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.menuCard {
  min-height: 50vh;
  max-height: calc(92vh - 32px);
  overflow: hidden;
}

.tableContent {
  max-height: calc(82vh - 32px);
  overflow: auto;
}

.bodyContent {
  padding-top: 0.5rem;
}
.newButton {
  text-align: end;
}
</style>
